/*import Vue from 'vue'*/

import EventUtils from '@/utils/EventUtils.js';
import MapUtils from '@/utils/MapUtils.js';
import ConstUtils from '@/utils/ConstUtils.js'

const state = {
  list: [],
  map: {},
  active: [],
  inactive: [],
  total: 0,
};

const getters = {
    employees_found: state => state,
};

const actions = {
};

const mutations = {
  SOCKET_ONMESSAGE (state, event)  {
    if (event.status == EventUtils.STATUS.COMPLETE && event.statusCode == EventUtils.CODE.OK) {
      var inactiveList = [];
      var activeList = [];
      if (event.Payload && event.Payload.EmployeeMap) {
        var employees = event.Payload.EmployeeMap;
        state.map = MapUtils.updateMap(employees, state.map);
        state.list = Object.values(state.map);
        state.list.forEach(function(item) {
          if (item.state === ConstUtils.EMPLOYEE.VALUES.STATE.ACTIVE) {
            activeList.push(item);
          } else {
            inactiveList.push(item);
          }
        });
        state.inactive = inactiveList;
        state.active = activeList;
        if (event.Payload.count) {
          state.total = event.Payload.count;
        }
      }
      if (event.Payload && event.Payload.Employee) {
        var employee = event.Payload.Employee;
        state.map[employee[ConstUtils.FIELDS.ID]] = employee;
        state.list = Object.values(state.map);
        state.list.forEach(function(item) {
          if (item.deleted == ConstUtils.EMPLOYEE.VALUES.STATE.ACTIVE) {
            activeList.push(item);
          } else {
            inactiveList.push(item);
          }
        });
        state.inactive = inactiveList;
        state.active = activeList;
      }
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};