
import RcDomainObject from "@/domain/common/RcDomainObject.js";
import User from "../user/User.js";

export default class Role extends RcDomainObject {
  static MODEL_NAME = User.MODEL_NAME + "Role";
      
  static FIELDS = {
      NAME: "name",
  }
  
  constructor(domain, data) {
    super(domain, data, Role.MODEL_NAME);
  }

  fields() {
    return Role.FIELDS;
  }
  
  isAdministrator() {
    return this.name() === "administrator";
  }
  
  isDriver() {
    return this.name() === "driver";
  }
  
  isManager() {
    return this.name() === "manager";
  }
  
  isOwner() {
    return this.name() === "owner";
  }
  
  name() {
    return this.getString(Role.FIELDS.NAME);
  }
}