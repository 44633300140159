
import RcDomainObject from '@/domain/common/RcDomainObject.js';

export default class RcError extends RcDomainObject {
  
  static Fields = {
      MessageId: "messageId",
      Hint: "hint",
  }
  
  static Values = {
      MessageIds: {
        InvoiceAlreadyPaid: "InvoiceAlreadyPaidId", 
      },
  }
  
  constructor(domain, data) {
    super(domain, data);
  }
  
  isInvoiceAlreadyPaid() {
    var messageId = this.getString(RcError.Fields.MessageId);
    return RcError.Values.MessageIds.InvoiceAlreadyPaid == messageId; 
  }
}

