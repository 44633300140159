/*import Vue from 'vue'*/
import EventUtils from '@/utils/EventUtils.js';
import MapUtils from '@/utils/MapUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';

const state = {
  list: [],
  map: {},
};

const getters = {
  roles_found: (state) => state,
};

const actions = {};

const mutations = {
  SOCKET_ONMESSAGE (state, event)  {
    if (event.status == EventUtils.STATUS.COMPLETE && event.statusCode == EventUtils.CODE.OK) {
      if (event.Payload && event.Payload.UserRoleMap) {
        var roles = event.Payload.UserRoleMap;
        state.map = MapUtils.updateMap(roles, state.map);
        state.list = Object.values(state.map);
      }
      if (event.Payload && event.Payload.UserRole) {
        var role = event.Payload.UserRole;
        state.map[role[ConstUtils.FIELDS.ID]] = role;
        state.list = Object.values(state.map);
      }
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};