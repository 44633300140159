
import RcDomainObject from "@/domain/common/RcDomainObject.js";
import NoteMap from "@/domain/model/schedule/question/option/note/NoteMap.js";

import Catalog from "@/domain/session/Catalog.js";

//import StringUtils from '@/utils/StringUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';

export default class Option extends RcDomainObject {
  
  static MODEL_NAME = "VehicleScheduleQuestionOption";
  
  static Fields = {
    NAME: 'name',
    VALUE: 'value',
    POSITION: 'position',
    CATEGORY: 'category',
    NOTES: 'VehicleScheduleQuestionNoteMap',
    TYPE: "type",
    Index: "index"
  }
  
  static Values = {
    Types: {
      
    }
  }
  
  constructor(domain, data) {
    super(domain, data, Option.MODEL_NAME);
    this.C = new Catalog();
  }

  repaired = false;
  notes = "";
  
  find() {
    return this.domain.options().findById(this.id());
  }
  
  type() {
    return this.getString(Option.Fields.TYPE);
  }

  noteMap() {
    return new NoteMap(this.domain, this.get(Option.Fields.NOTES))
  }
  
  hasNotes() {
    var map = this.noteMap();
    return map.length() > 0;
  }
  
  position() {
    return this.getString(Option.Fields.POSITION);
  }
  
  name() {
    return this.getString(Option.Fields.NAME);
  }
  
  value() {
    return this.getString(Option.Fields.VALUE);
  }
  
  display(index) {
    if (index != null) {
      if (this.isRemark()) {
        return (index + 1) + ". " + "Remarks";
      }
      return (index + 1) + ". " + this.value();
    }
    return this.value();
  }
  
  category() {
    return this.getString(Option.Fields.CATEGORY);
  }
  
  isRemark() {
    return this.value() == "Minor Notes" ||
        this.value() == "User Major" ||
        this.value() == "Remarks";
  }
  
  isOk() {
    return this.value() === ConstUtils.QUESTIONS.VALUES.OK;
  }
  
  isNa() {
    return this.value() === ConstUtils.QUESTIONS.VALUES.NA;
  }
  
  isMajor() {
    return this.category() === ConstUtils.QUESTIONS.VALUES.MAJOR;
  }
  
  isMinor() {
    return this.category() === ConstUtils.QUESTIONS.VALUES.MINOR;
  }
}