
import EventUtils from '@/utils/EventUtils.js';
import UserEvents from '@/domain/model/user/UserEvents.js';

const state = {
    event: {},
};

const getters = {
    signin_event: state => state.event,
};

const actions = {
};

const mutations = {
  SOCKET_ONMESSAGE (state, event)  {
    if (event.status == EventUtils.STATUS.COMPLETE &&
        event.eventName == UserEvents.SignIn.Names.Response) {
      state.event = event;
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};