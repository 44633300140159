
import RcDomainObject from "../../../common/RcDomainObject.js";
import Company from "../../company/Company.js";
import CheckListItemOptionMap from "./option/CheckListItemOptionMap.js";
import StringUtils from '@/utils/StringUtils.js'

export default class CheckListItem extends RcDomainObject {
  
  static MODEL_NAME = 'CheckListItem';
  
  static Fields = {
      Index: "index",
      Name: "name",
      Type: "type",
      Company: "Company",
      CREATED_DATE: "createdDate",
      OptionMap : CheckListItemOptionMap.MODEL_NAME,
      MultiSelect: "multiSelect"
  }
  
  static Values = {
      Types: {
        Text: "text",
        Number: "number",
        List: "list",
        Bool: "bool"  
      }
  }
  
  constructor(domain, data) {
    super(domain, data, CheckListItem.MODEL_NAME);
  }

  fields() {
    return CheckListItem.Fields;
  }
  
  find() {
    var result = this.domain.checklists().items().findById(this.id());
    if (result.id() !== this.id()) {
      result = this;
    }
    
    return result;
  }

  populate() {
    var options = this.options();
    options.populate();
    this.withOptions(options);
    return this;
  }
  
  withOptions(options) {
    this.put(CheckListItem.Fields.OptionMap, options);
    return this;
  }
  
  options() {
    var data = this.get(CheckListItem.Fields.OptionMap);
    return new CheckListItemOptionMap(this.domain, data);
  }
  
  index() {
    return this.getInt(CheckListItem.Fields.Index);
  }
  
  withIndex(index) {
    this.put(CheckListItem.Fields.Index, index);
    return this;
  }
  
  name() {
    return this.getString(CheckListItem.Fields.Name);
  }
  
  withName(name) {
    this.put(CheckListItem.Fields.Name, name);
    return this;
  }

  type() {
    return this.getString(CheckListItem.Fields.Type);
  }
  
  withType(type) {
    this.put(CheckListItem.Fields.Type, type);
    return this;
  }
  
  withTypeString() {
    return this.withType(CheckListItem.Values.Types.Text);
  }
  
  withTypeInteger() {
    return this.withType(CheckListItem.Values.Types.Number);
  }
  
  withTypeList() {
    return this.withType(CheckListItem.Values.Types.List);
  }

  withTypeBool() {
    return this.withType(CheckListItem.Values.Types.Bool);
  }

  isTypeText() {
    return StringUtils.equals(CheckListItem.Values.Types.Text, this.type());
  }
  
  isTypeNumber() {
    return StringUtils.equals(CheckListItem.Values.Types.Number, this.type());
  }
  
  isTypeBool() {
    return StringUtils.equals(CheckListItem.Values.Types.Bool, this.type());
  }
  
  isTypeList() {
    return StringUtils.equals(CheckListItem.Values.Types.List, this.type())
        || this.isTypeBool();
  }
  
  company() {
    var data = this.get(CheckListItem.Fields.Company);
    var company = new Company(this.domain, data);
    return company;
  }
  
  withCompany(company) {
    this.put(CheckListItem.Fields.Company, company);
    return this;
  }
  
  multiSelect() {
    return this.getBoolean(CheckListItem.Fields.MultiSelect)
  }
  
  withMultiSelect(multiSelect) {
    this.put(CheckListItem.Fields.MultiSelect, multiSelect);
    return this;
  }
  copy() {
    var data = this._copy();
    return new CheckListItem(this.domain, data);
  }
}