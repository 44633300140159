
import RcDomainMap from "@/domain/common/RcDomainMap.js";

import Option from './Option.js';
import OptionUtils from './OptionUtils.js';

export default class OptionMap extends RcDomainMap {
  
  static MODEL_NAME = "VehicleScheduleQuestionOptionMap";
  
  constructor(domain, data) {
    super(domain, data);
  }

  find() {
    var keys = this.keys();
    var found = new OptionMap(this.domain, {} );
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var data = this.domain.options().findById(key);
      var item = new Option(this.domain, data);
      if (!item.isNew()) {
        found.add(item);
      }
    }
    
    return found;
  }
  
  list() {
    var values = [];
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getOption(key);
      values.push(item);
    }
    return values;
  }
  
  first() {
    var list = this.list();
    var option = new Option(this.domain);
    if (list.length > 0) {
      option = list[0];
    }
    return option;
  }
  
  sort() {
    var sorted = [];
    sorted = this.list();
    sorted = sorted.sort(OptionUtils.SortByPosition);
    return sorted;
  }
  
  removeRemarks() {
    var found = new OptionMap(this.domain, {});
    var list = this.list();
    for (var index = 0; index < list.length; index++) {
      var option = list[index];
      if (!option.isRemark()) {
        found.add(option);
      }
    }
      
    return found;
  }
  
  majors() {
    var found = new OptionMap(this.domain, {});
    var list = this.list();
    for (var index = 0; index < list.length; index++) {
      var option = list[index];
      if (option.isMajor()) {
        found.add(option);
      }
    }
      
    return found;
  }
  
  hasMajor() {
    var list = this.list();
    for (var index = 0; index < list.length; index++) {
      var option = list[index];
      if (option.isMajor()) {
        return true;
      }
    }
      
    return false;
  }
  
  minors() {
    var found = new OptionMap(this.domain, {});
    var list = this.list();
    for (var index = 0; index < list.length; index++) {
      var option = list[index];
      if (option.isMinor()) {
        found.add(option);
      }
    }
      
    return found;
  }
  
  hasMinor() {
    var list = this.list();
    for (var index = 0; index < list.length; index++) {
      var option = list[index];
      if (option.isMinor()) {
        return true;
      }
    }
      
    return false;
  }
  
  hasNa() {
    var list = this.list();
    for (var index = 0; index < list.length; index++) {
      var option = list[index];
      if (option.isNa()) {
        return true;
      }
    }
      
    return false;
  }
  
  hasOk() {
    var list = this.list();
    for (var index = 0; index < list.length; index++) {
      var option = list[index];
      if (option.isOk()) {
        return true;
      }
    }
      
    return false;
  }
  
  getOption(key) {
    var value = super.get(key);
    return new Option(this.domain, value);
  }
  
  copy() {
    var data = this._copy();
    return new OptionMap(this.domain, data);
  }
}