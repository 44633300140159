<template>
  <div class="rc-container rc-text-label ">
  
    <div v-if="auth_user" class="row rc-text-label">
      <div class="rc-box-col-2" />
      <div class="rc-box-col-13">
        <router-link to="/portal/operator/home" class="rc-text-medium rc-padding-normal rc-border-round" onclick="w3_close()" >Home</router-link>
        <router-link to="/portal/operator/whatsnew" class="rc-text-medium rc-padding-normal rc-border-round" onclick="w3_close()" >Whats New!</router-link>
        <router-link to="/portal/operator/compliance" v-if="compliance" class="rc-text-medium rc-padding-normal rc-border-round" onclick="w3_close()" >Compliance</router-link>
        
        <!-- <router-link :to="'/portal/operator/' + companyId + '/vehicles'" v-if="vehicles" class="rc-text-medium rc-padding-normal rc-border-round" onclick="w3_close()" >Vehicles and Trailers</router-link> -->
        <router-link to="/portal/operator/vehicles" v-if="vehicles" class="rc-text-medium rc-padding-normal rc-border-round" onclick="w3_close()" >Vehicles and Trailers</router-link>
        
        <router-link :to="'/portal/operator/' + companyId + '/inspections'" v-if="inspections" class="rc-text-medium rc-padding-normal rc-border-round" onclick="w3_close()" >Inspections</router-link>
        <router-link :to="'/portal/operator/' + companyId + '/hours'" v-if="timesheets" class="rc-text-medium rc-padding-normal rc-border-round" onclick="w3_close()">Hours of Service</router-link>
        
        <router-link to="/portal/operator/checkins" v-if="checkins" class="rc-text-medium rc-padding-normal rc-border-round" onclick="w3_close()">Check Ins</router-link>
        
        <router-link :to="'/portal/operator/' + companyId + '/users'" class="rc-text-medium rc-padding-normal rc-border-round" onclick="w3_close()" >Users</router-link>
        
        <router-link to="/portal/operator/invoices" class="rc-text-medium rc-padding-normal rc-border-round" onclick="w3_close()" >Invoices</router-link>
        
        <router-link :to="'/portal/operator/' + companyId + '/billing/display'" class="rc-text-medium rc-padding-normal rc-border-round" onclick="w3_close()" >Billing</router-link>
        
        <router-link to="/portal/operator/company/edit" class="rc-text-medium rc-padding-normal rc-border-round" onclick="w3_close()" >Company</router-link>
        <router-link to="/portal/operator/checklists" v-if="checklists" class="rc-text-medium rc-padding-normal rc-border-round" onclick="w3_close()" >Checklists</router-link>
      </div>
    </div>
    <div v-else class="row">
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ContentUtils from '@/utils/ContentUtils.js';

export default {
  name: 'app-menu',
  props: {
    isSmall: { type: Boolean, default: false },
  },
  computed :{
    ...mapGetters([
                   'auth_user', 
                   'auth_client',
                   'auth_connected',
                   'domain',
                 ]),
    companyName: function() {
      if (!this.domain || !this.domain.session()) {
        return "";
      }
      var name = this.domain.session().company().name();
      return ContentUtils.unescape(name);
    },
    companyId: function() {
      if (!this.domain || !this.domain.session()) {
        return "";
      }
      var name = this.domain.session().company().id();
      return ContentUtils.baseEncode(name);
    },
    inspections: function() {
      if (!this.domain || !this.domain.session()) {
        return false;
      }
      var result = true;
      result = this.domain.session().company().features().find().allowsInspections();
      return result;
    },
    timesheets: function() {
      if (!this.domain || !this.domain.session()) {
        return false;
      }
      var result = true;
      result = this.domain.session().company().features().find().allowsTimesheets();
      return result;
    },
    checkins: function() {
      if (!this.domain || !this.domain.session()) {
        return false;
      }
      var result = true;
      result = this.domain.session().company().features().find().allowsCheckIns();
      return result;
    },
    checklists: function() {
      if (!this.domain || !this.domain.session()) {
        return false;
      }
      var result = true;
      result = this.domain.session().company().features().find().allowsCheckLists();
      return result;
    },
    vehicles: function() {
      if (!this.domain || !this.domain.session()) {
        return false;
      }
      var result = true;
      if (this.inspections) {
        return true;
      }
      result = this.domain.session().company().features().find().allowsVehicles();
      return result;
    },
    schedules: function() {
      return true;
    },
    compliance: function() {
      if (!this.domain || !this.domain.session()) {
        return false;
      }
      var result = true;
      result = this.domain.session().company().features().find().allowsCompliance();
      return result;
    },
    hasCompanys: function() {
      var user = this.domain.session().user();
      var employees = this.domain.employees().findByUserId(user.id());
      return employees.length() > 1;
    },
  },
  watch: {
  },
  methods: {
    decode: function(value) {
      return ContentUtils.unescape(value);
    },
    first: function(){
      if (!this.domain || !this.domain.session()) {
        return "";
      }
      var name = this.domain.session().user().firstDisplay();
      return name;
    },
    email: function() {
      if (!this.domain || !this.domain.session()) {
        return "";
      }
      return this.domain.session().user().emailDisplay();
    },
  },
}
</script>