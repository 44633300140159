
export default class CompanyUtils {
  
  static SortByName = function(a,b) {
    if (a.name() > b.name()) {
      return 1;
    }
    if (a.name() < b.name()) {
      return -1;
    }
    return 0;
  }

  static SortByNameDesc = function(a,b) {
    if (a.name() < b.name()) {
      return 1;
    }
    if (a.name() > b.name()) {
      return -1;
    }
    return 0;
  }

}