
import RcDomainObject from "@/domain/common/RcDomainObject.js";
//import ContentUtils from "@/utils/ContentUtils.js";
import StringUtils from "@/utils/StringUtils.js";

export default class Signature extends RcDomainObject {

  static MODEL_NAME = 'UserSignature';
  
  static FIELDS = {
      VALUE: "value",
  }
  
  constructor(domain, data) {
    super(domain, data, Signature.MODEL_NAME);
  }

  find() {
    return this.domain.users().signatures().findById(this.id());
  }
  
  fields() {
    return Signature.FIELDS;
  }
  
  value() {
    return this.getString(Signature.FIELDS.VALUE);
  }

  image() {
    var value = this.value();
    if (!StringUtils.isEmpty(value)) {
      var data = value.substring(1, value.length - 1).split(',')
      var dataArray = new Uint8Array(data).reduce((data, byte) => data + String.fromCharCode(byte), '')
      return 'data:image/jpeg;base64,' + btoa(dataArray)
    }
    return '';
  }
  
}