/*import Vue from 'vue'*/
import EventUtils from '@/utils/EventUtils.js';
import MapUtils from '@/utils/MapUtils.js';
import ConstUtils from '@/utils/ConstUtils.js'

const state = {
  list: [],
  map: {},
};

const getters = {
    features_store: state => state,
};

const actions = {
};

const mutations = {
  SOCKET_ONMESSAGE (state, event)  {
    if (event.status == EventUtils.STATUS.COMPLETE && event.statusCode == EventUtils.CODE.OK) {
      if (event.Payload && event.Payload.FeatureMap) {
        var features = event.Payload.FeatureMap;
        state.map = MapUtils.updateMap(features, state.map);
        state.list = Object.values(state.map);
      }
      if (event.Payload && event.Payload.Feature) {
        var obj = event.Payload.Feature;
        state.map[obj[ConstUtils.FIELDS.ID]] = obj;
        state.list = Object.values(state.map);
      }
    }
    
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};