const state = {
  allFilter: false,
  todayFilter: true,
  currentMonthFilter: false,
}

const getters = {
  Timesheets_AllFilter : state => state.allFilter,
  Timesheets_TodayFilter : state => state.todayFilter,
  Timesheets_CurrentMonthFilter : state => state.currentMonthFilter,
}

const actions = {
  Timesheets_AllFilterToggle({ commit }) {
    commit("allFilterToggle");
  },
  Timesheets_TodayFilterToggle({ commit }) {
    commit("todayFilterToggle");
  },
  Timesheets_CurrentMonthFilterToggle({ commit }) {
    commit("currentMonthFilterToggle");
  }
}

const mutations = {
  allFilterToggle(state) {
    state.allFilter = !state.allFilter;
    state.currentMonthFilter = false;
    state.todayFilter = false;
  },
  todayFilterToggle(state) {
    state.todayFilter = !state.todayFilter;
    state.currentMonthFilter = false;
    state.allFilter = false;
  },
  currentMonthFilterToggle(state) {
    state.currentMonthFilter = !state.currentMonthFilter;
    state.todayFilter = false;
    state.allFilter = false;
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
};