
import RcDomainMap from "../../common/RcDomainMap.js";
import CheckIn from "./CheckIn.js";
import CheckInUtils from "./CheckInUtils.js";

export default class CheckInMap extends RcDomainMap {
  
  static MODEL_NAME = "CheckInMap";

  constructor(domain, data) {
    super(domain, data, CheckInMap.MODEL_NAME);
  }
  
  find() {
    var keys = this.keys();
    var found = new CheckInMap(this.domain, {} );
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var data = this.domain.checkins().findById(key);
      var item = new CheckIn(this.domain, data);
      if (!item.isNew()) {
        found.add(item);
      }
    }
    
    return found;
  }
  
  list() {
    var values = [];
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getCheckIn(key);
      values.push(item);
    }
    return values;
  }
  
  sort() {
    var list = this.list();
    return list.sort(CheckInUtils.sortByCreatedDate);
  }
  
  first() {
    var all = this.list();
    if (all.length > 0) {
      return all[0];
    }
    return new CheckIn(this.domain);
  }
  
  getCheckIn(key) {
    var value = super.get(key);
    return new CheckIn(this.domain, value);
  }
  
  copy() {
    var data = this._copy();
    return new CheckInMap(this.domain, data);
  }
  
  findForDay(rcDate, employee) {
    var day = rcDate.day().value();
    var month = rcDate.month().value();
    var year = rcDate.year().value();
    
    var map = new CheckInMap(this.domain);
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getCheckIn(key);
      var date = item.createdDateV2();
      if (date.day().value() == day && date.year().value() == year && date.month().value() == month) {
        if (item.employee().id() === employee.id()) {
          map.add(item);
        }
      }
    }
    
    return map;
  }
  
  findForEmployee(employee) {
    var map = new CheckInMap(this.domain);
    var keys = this.keys();
    
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getCheckIn(key);
      if (item.employee().id() === employee.id()) {
        map.add(item);
      }
    }
    
    return map;
  }
}