
import EventUtils from '@/utils/EventUtils.js';
import InvoiceEvents from '@/domain/model/invoice/InvoiceEvents.js';

const state = {
    pageNumber: 0,
    min: 0,
    max: 0,
    total: 0,
    pageSize: 20,
    pageData: [],
    event: {},
}

const getters = {
  InvoiceModel_PaymentLinkResponseEvent: state => state.event,
  pageNumber: function() {
    return state.pageNumber;
  },
  
  InvoicesModel_Getters: function() {
    return {
      pageNumber: function() {
        return state.pageNumber;
      },
      pageSize: function() {
        return state.pageSize;
      },
      min: function() {
        if (state.total == 0) {
          return 0;
        }
        return (state.pageNumber * state.pageSize) + 1;
      },
      max: function() {
        var t = (state.pageNumber + 1) * state.pageSize;
        return state.total < t ? state.total : t;
      },
      total: function() {
        return state.total;
      },
      nextPage: function() {
        if (state.total > (state.pageSize * state.pageNumber) + state.pageSize) {
          state.pageNumber++;
        }
      },
      prevPage: function() {
        if (state.pageNumber > 0) {
          state.pageNumber--;
        }
      },
      withData: function(map) {
        state.pageMap = map;
        state.pageData = Object.values(map.data);
      },
      clearData: function() {
        state.pageData = [];
      },
      pageData: function() {
        return state.pageData;
      },
      sizeData: function() {
        return state.pageData.length;
      },
      withTotal: function(total) {
        if (total) {
          state.total = total;
        }
      }
    };
  },
}

const actions = {
  Timesheets_AllFilterToggle({ commit }) {
    commit("allFilterToggle");
  },
  InvoicesModel_Actions({ commit }) {
    return {
      nextPage: function() {
        commit("nextPage");
      },
      prevPage: function() {
        commit("prevPage");
      }
    }
  }
}

const mutations = {
  allFilterToggle(state) {
    state.allFilter = !state.allFilter;
    state.currentMonthFilter = false;
    state.todayFilter = false;
  },
  nextPage(state) {
    state.pageNumber++;
  },
  prevPage(state) {
    state.pageNumber--;
  },
  SOCKET_ONMESSAGE (state, event)  {
    if (event.status == EventUtils.STATUS.COMPLETE &&
        event.eventName == InvoiceEvents.CreatePaymentLink.Names.Response) {
      state.event = event;
    }
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
};