
import RcObject from "./RcObject";

import CanadianEnglish from "@/domain/session/CanadianEnglish.js"
import DateUtils  from '@/utils/DateUtils.js';
import StringUtils  from '@/utils/StringUtils.js';
import RcTime from '@/domain/session/time/RcTime.js';
import RcDate from '@/domain/session/time/RcDate.js';

export default class RcDomainObject extends RcObject {

  static DefaultFields = {
    CREATED_BY: 'createdBy',
    CREATED_DATE: 'createdDate',
    
    MODIFIED_BY: 'modifiedBy',
    MODIFIED_DATE: 'modifiedDate',
    STATE: 'state',
    ACTIVE: 'active',
    MODEL_NAME: '@class',
    TIME_ZONE: 'timeZone',
    ID: '@rid',
  }
  
  static DefaultValues = {
    State: {
      ACTIVE: 'active',
      INACTIVE: 'inactive',
      DELETED: 'deleted',
    },
  }
  
  constructor(domain, data, modelName) {
    super(data);
    if (modelName) {
      this.put(RcDomainObject.DefaultFields.MODEL_NAME, modelName);
    }
    this.domain = domain;
  }
  
  logger() {
    return this.domain.logger();
  }
  
  session() {
    return this.domain.session();
  }
  
  withStateActive() {
    this.put(RcDomainObject.DefaultFields.STATE, RcDomainObject.DefaultValues.State.ACTIVE);
    return this;
  }
  
  withCreatedBy(user) {
    this.put(RcDomainObject.DefaultFields.CREATED_BY, user.data);
    return this;
  }
  
  withCreatedDateNow() {
    this.put(RcDomainObject.DefaultFields.CREATED_DATE, DateUtils.now());
    return this;
  }
  
  createdDate() {
    return this.get(RcDomainObject.DefaultFields.CREATED_DATE);
  }
  
  createdDateV2() {
    var createdDate = this.createdDate();
    var timeZone = this.timeZone();
    var rcDate = new RcDate(new Date(createdDate), timeZone);
    return rcDate;
  }
  
  createdDateDisplay() {
    return DateUtils.toDate(this.createdDate());
  }
  
  modifiedDate() {
    return this.get(RcDomainObject.DefaultFields.MODIFIED_DATE);
  }

  modifiedDateV2() {
    var date = this.domain.session().time().at(this.modifiedDate());
    return date;
  }

  modifiedDateDisplay() {
    return DateUtils.toDate(this.modifiedDate());
  }
  
  modelName() {
    return this.get(RcDomainObject.DefaultFields.MODEL_NAME);
  }
  
  with(value) {
    if (value.data) {
      this.put(value.modelName(), value.data);
    } else {
      this.put(value.modelName(), value);
    }
    return this;
  }
  
  timeZone() {
    var tz = this.getString(RcDomainObject.DefaultFields.TIME_ZONE);
    if (StringUtils.isEmpty(tz) || tz === "0") {
      tz = RcTime.Zone.Names.CANADA_TORONTO;
    }
    return tz;
  }

  lang() {
    if (this.domain) {
      var session = this.domain.session();
      if (session) {
        return session.lang();
      }
    }
    return new CanadianEnglish();
  }
  
  state() {
    var state = this.getString(RcDomainObject.DefaultFields.STATE);
    if (state) {
      return state;
    }
    var active = this.getString(RcDomainObject.DefaultFields.ACTIVE);
    if (active) {
      return "active";
    }
    return "inactive";
  }
  
  isStateActive() {
    var state = this.state();
    if (StringUtils.isEmpty(state)) {
      var active = this.get("active");
      if (active) {
        return true;
      }
      return false;
    }
    return state === RcDomainObject.DefaultValues.State.ACTIVE;
  }
  
  isStateInactive() {
    var state = this.state();
    if (StringUtils.isEmpty(state)) {
      return true;
    }
    return state === RcDomainObject.DefaultValues.State.INACTIVE;
  }
  
  isStateDeleted() {
    var state = this.state();
    if (StringUtils.isEmpty(state)) {
      var deleted = this.getString("deleted");
      if (deleted === "deleted") {
        return true;
      } else {
        return false;
      }
    }
    return state === RcDomainObject.DefaultValues.State.DELETED;
  }
  
  isSystem() {
    return this.id() === "#13:0";
  }
  
  isPointer() {
    return this.length() == 2 && this.has(RcDomainObject.DefaultFields.MODEL_NAME) && this.has(RcDomainObject.DefaultFields.ID);
  }
}