
import MapUtils from '@/utils/MapUtils.js';

export default class InvoiceEvents {
  
  constructor(eventsDomain) {
    this.eventsDomain = eventsDomain;
    this.domain = this.eventsDomain.domain;
  }
  
  contains(name) {
    if (name) {
      //
    }
    return false;
  }
  
  list(filter) {
    var event = InvoiceEvents.List.Request(filter);
    return this.eventsDomain.initRequest(event);
  }
  
  rebuild(data) {
    var event = InvoiceEvents.Rebuild.Request(data);
    return this.eventsDomain.initRequest(event);
  }
  
  rebuildPdf(data) {
    var event = InvoiceEvents.RebuildPdf.Request(data);
    return this.eventsDomain.initRequest(event);
  }
  
  rebuildQb(data) {
    var event = InvoiceEvents.RebuildQb.Request(data);
    return this.eventsDomain.initRequest(event);
  }
  
  chargeDetails(data) {
    var event = InvoiceEvents.ChargeDetails.Request(data);
    return this.eventsDomain.initRequest(event);
  }
  
  removeCard(data) {
    var event = InvoiceEvents.RemoveCard.Request(data);
    return this.eventsDomain.initRequest(event);
  }

  listCards(companyMap) {
    var event = InvoiceEvents.ListCards.Request(companyMap);
    return this.eventsDomain.initRequest(event);
  }

  createPaymentLink(invoice) {
    var event = InvoiceEvents.CreatePaymentLink.Request(invoice);
    return this.eventsDomain.initRequest(event);
  }
  
  save(data) {
    var event = InvoiceEvents.Save.Request(data);
    return this.eventsDomain.initRequest(event);
  }
  
  emailTo(data) {
    var event = InvoiceEvents.EmailTo.Request(data);
    return this.eventsDomain.initRequest(event);
  }
  emailThanks(data) {
    var event = InvoiceEvents.EmailThankYou.Request(data);
    return this.eventsDomain.initRequest(event);
  }
  
  paid(invoice, kvps) {
    var event = InvoiceEvents.Paid.Request(invoice, kvps);
    return this.eventsDomain.initRequest(event);
  }
  
  charge(invoice, kvps) {
    var event = InvoiceEvents.Charge.Request(invoice, kvps);
    return this.eventsDomain.initRequest(event);
  }
  
  saveSettings(settings, address) {
    var event = InvoiceEvents.SettingsAddressSave.Request(settings, address);
    return this.eventsDomain.initRequest(event);
  }
  
  static Save = {
    Names: {
      Request: 'InvoiceSaveRequestEvent',
      Response: 'InvoiceSaveResponseEvent',
    },

    Errors: {
      NotFound: {
        id: 'NotFound',
        msg: 'NotFound',
        details: ''
      },
    },

    Request: function(invoice) {
      var event = {
        Invoice: invoice.data,
        eventName: InvoiceEvents.Save.Names.Request,
        handler: InvoiceEvents.Save.Names.Request,
      }
      return event;
    },
  }
  
  static Rebuild = {
      Names: {
        Request: 'CompanyMonthlyInvoiceRequestEvent',
        Response: 'CompanyMonthlyInvoiceResponseEvent',
      },

      Errors: {
        NotFound: {
          id: 'NotFound',
          msg: 'NotFound',
          details: ''
        },
      },

      Request: function(invoice) {
        var event = {
          Invoice: invoice.id(),
          month: invoice.month(),
          year: invoice.year(),
          Company: invoice.company().id(),
          eventName: InvoiceEvents.Rebuild.Names.Request,
          handler: InvoiceEvents.Rebuild.Names.Request,
        }
        return event;
      },
  }
  
  static RebuildPdf = {
      Names: {
        Request: 'InvoiceRebuildPdfRequestEvent',
        Response: 'InvoiceRebuildPdfResponseEvent',
      },

      Errors: {
        NotFound: {
          id: 'NotFound',
          msg: 'NotFound',
          details: ''
        },
      },

      Request: function(invoice) {
        var event = {
          Invoice: invoice.id(),
          month: invoice.month(),
          year: invoice.year(),
          Company: invoice.company().id(),
          eventName: InvoiceEvents.RebuildPdf.Names.Request,
          handler: InvoiceEvents.RebuildPdf.Names.Request,
        }
        return event;
      },
  }
  
  static RebuildQb = {
      Names: {
        Request: 'InvoiceRebuildQuickBooksRequestEvent',
        Response: 'InvoiceRebuildQuickBooksResponseEvent',
      },

      Errors: {
        NotFound: {
          id: 'NotFound',
          msg: 'NotFound',
          details: ''
        },
      },

      Request: function(invoice) {
        var event = {
          Invoice: invoice.id(),
          eventName: InvoiceEvents.RebuildQb.Names.Request,
          handler: InvoiceEvents.RebuildQb.Names.Request,
        }
        return event;
      },
  }
  
  static ChargeDetails = {
      Names: {
        Request: 'InvoiceChargeDetailsRequestEvent',
        Response: 'InvoiceChargeDetailsResponseEvent',
      },

      Errors: {
        NotFound: {
          id: 'NotFound',
          msg: 'NotFound',
          details: ''
        },
      },

      Request: function(invoice) {
        var event = {
          Invoice: invoice.id(),
          eventName: InvoiceEvents.ChargeDetails.Names.Request,
          handler: InvoiceEvents.ChargeDetails.Names.Request,
        }
        return event;
      },
  }
  
  static RemoveCard = {
    Names: {
      Request: 'RemoveCardCommandOperation',
      Response: 'RemoveCardCommandNotification',
    },

    Errors: {
      NotFound: {
        id: 'NotFound',
        msg: 'NotFound',
        details: ''
      },
    },

    Request: function(data) {
      var event = {
        eventName: InvoiceEvents.RemoveCard.Names.Request,
        handler: InvoiceEvents.RemoveCard.Names.Request,
      }

      var params = {
        Company: data.pointer().data,
      }

      event["Params"] = params;
      return event;
    },
  }

  static ListCards = {
    Names: {
      Request: 'CardListQueryOperation',
      Response: 'CardListQueryNotification',
    },

    Errors: {
      NotFound: {
        id: 'NotFound',
        msg: 'NotFound',
        details: ''
      },
    },

    Request: function(companyMap) {
      var event = {
        eventName: InvoiceEvents.ListCards.Names.Request,
        handler: InvoiceEvents.ListCards.Names.Request,
      }

      var params = {
        CompanyMap: companyMap.data,
      }

      event["Params"] = params;
      return event;
    },
  }

  static CreatePaymentLink = {
      Names: {
        Request: 'InvoiceCreatePaymentLinkRequestEvent',
        Response: 'InvoiceCreatePaymentLinkResponseEvent',
      },

      Errors: {
        NotFound: {
          id: 'NotFound',
          msg: 'NotFound',
          details: ''
        },
        find: function(error) {
          return error;
        }
      },

      Request: function(invoice) {
        var event = {
          Invoice: invoice.data,
          eventName: InvoiceEvents.CreatePaymentLink.Names.Request,
          handler: InvoiceEvents.CreatePaymentLink.Names.Request,
        }
        return event;
      },
  }
  
  static EmailTo = {
      Names: {
        Request: 'InvoiceEmailToRequestEvent',
        Response: 'InvoiceEmailToResponseEvent',
      },

      Errors: {
        NotFound: {
          id: 'NotFound',
          msg: 'NotFound',
          details: ''
        },
      },

      Request: function(invoice) {
        var event = {
          Invoice: invoice.id(),
          Company: invoice.company().id(),
          eventName: InvoiceEvents.EmailTo.Names.Request,
          handler: InvoiceEvents.EmailTo.Names.Request,
        }
        return event;
      },
  }
  
  static EmailThankYou = {
      Names: {
        Request: 'InvoiceEmailThankYouRequestEvent',
        Response: 'InvoiceEmailThankYouResponseEvent',
      },

      Errors: {
        NotFound: {
          id: 'NotFound',
          msg: 'NotFound',
          details: ''
        },
      },

      Request: function(invoice) {
        var event = {
          Invoice: invoice.id(),
          Company: invoice.company().id(),
          eventName: InvoiceEvents.EmailThankYou.Names.Request,
          handler: InvoiceEvents.EmailThankYou.Names.Request,
        }
        return event;
      },
  }
  
  static Paid = {
      Names: {
        Request: 'InvoicePaidRequestEvent',
        Response: 'InvoicePaidResponseEvent',
      },

      Errors: {
        NotFound: {
          id: 'NotFound',
          msg: 'NotFound',
          details: ''
        },
      },

      Request: function(invoice, kvps) {
        var event = {
          Invoice: invoice.number(),
          eventName: InvoiceEvents.Paid.Names.Request,
          handler: InvoiceEvents.Paid.Names.Request,
        }
        event = MapUtils.updateMap(event, kvps);
        return event;
      },
  }

  static Charge = {
      Names: {
        Request: 'InvoiceChargeRequestEvent',
        Response: 'InvoiceChargeResponseEvent',
      },

      Errors: {
        NotFound: {
          id: 'NotFound',
          msg: 'NotFound',
          details: ''
        },
      },

      Request: function(invoice, kvps) {
        var event = {
          Invoice: invoice.number(),
          eventName: InvoiceEvents.Charge.Names.Request,
          handler: InvoiceEvents.Charge.Names.Request,
        }
        MapUtils.updateMap(event, kvps);
        return event;
      },
  }

  static List = {
      Names: {
        Request: 'InvoiceListRequestEvent',
        Response: 'InvoiceListResponseEvent',
      },

      Errors: {
        NotFound: {
          id: 'NotFound',
          msg: 'NotFound',
          details: ''
        },
      },

      Request: function(filter) {
        var event = {
          filter: filter.data,
          eventName: InvoiceEvents.List.Names.Request,
          handler: InvoiceEvents.List.Names.Request,
        }
        return event;
      },
    }
  
  static SettingsAddressSave = {
      Names: {
        Request: 'InvoiceSettingsAddressSaveRequestEvent',
        Response: 'InvoiceSettingsAddressSaveResponseEvent',
      },

      Errors: {
        //
      },

      Request: function(settings, address) {
        var request = {
          'InvoiceSettings': settings,
          'Address': address,
          'eventName': InvoiceEvents.SettingsAddressSave.Names.Request,
          'handler': InvoiceEvents.SettingsAddressSave.Names.Request,
        };
        
        return request;
      },
    }
  
}