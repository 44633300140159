
import RcDomainMap from "../../../common/RcDomainMap.js";
import CheckListItem from "./CheckListItem.js";
import CheckListItemUtils from "./CheckListItemUtils.js";

export default class CheckListItemMap extends RcDomainMap {
  
  static MODEL_NAME = "CheckListItemMap";

  constructor(domain, data) {
    super(domain, data, CheckListItemMap.MODEL_NAME);
  }
  
  find() {
    var keys = this.keys();
    var found = new CheckListItemMap(this.domain, {} );
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getChecklistItem(key);
      if (item.isNew()) {
        found.add(item);
      } else {
        var data = this.domain.checklists().items().findById(key);
        item = new CheckListItem(this.domain, data);
        found.add(item);
      }
    }
    
    return found;
  }
  
  populate() {
    var found = this.find();
    this.addAll(found);
    var list = this.list();
    for (var i = 0; i < list.length; i++) {
      var item = list[i];
      item.populate();
    }
    return this
  }
  
  list() {
    var values = [];
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getChecklistItem(key);
      values.push(item);
    }
    return values;
  }
  
  sortByIndex() {
    var list = this.list();
    var fnc = CheckListItemUtils.SortByIndex;
    var sorted = list.sort(fnc);
    return sorted;
  }
  
  first() {
    var all = this.list();
    if (all.length > 0) {
      return all[0];
    }
    return new CheckListItem(this.domain);
  }
  
  getChecklistItem(key) {
    var value = super.get(key);
    return new CheckListItem(this.domain, value);
  }
  
  getChecklistItemByIndex(index) {
    var list = this.sortByIndex();
    return list[index];
  }
  
  copy() {
    var data = this._copy();
    return new CheckListItemMap(this.domain, data);
  }
  
}