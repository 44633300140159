
import RcDomainMap from "../../common/RcDomainMap.js";
import Image from "./Image.js";
import ImageUtils from "./ImageUtils.js";

export default class ImageMap extends RcDomainMap {
  
  static MODEL_NAME = "ImageMap";

  constructor(domain, data) {
    super(domain, data, ImageMap.MODEL_NAME);
  }
  
  find() {
    var keys = this.keys();
    var found = new ImageMap(this.domain, {} );
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getImage(key);
      if (item.isNew()) {
        found.add(item);
      } else {
        var data = this.domain.images().findById(key);
        item = new Image(this.domain, data);
        if (!item.isNew()) {
          found.add(item);
        }
      }
    }
    
    return found;
  }
  
  list() {
    var values = [];
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getImage(key);
      values.push(item);
    }
    return values;
  }
  
  sort() {
    var list = this.list();
    return list.sort(ImageUtils.SortByCreatedDate);
  }
  
  sortByIndex() {
    var list = this.list();
    return list.sort(ImageUtils.SortByIndex);
  }
  
  first() {
    var all = this.list();
    if (all.length > 0) {
      return all[0];
    }
    return new Image(this.domain);
  }
  
  getImage(key) {
    var value = super.get(key);
    return new Image(this.domain, value);
  }
  
  copy() {
    var data = this._copy();
    return new ImageMap(this.domain, data);
  }
  
}