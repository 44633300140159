<template>
<div>
  <div class="" id="top">
    <div v-if="showOldMenu()" class="rc-container">

       <SignOutDialog v-show="showSignOut"   @close="closeSignOutDialog" />
    
        
      <div class="rc-row">
        <div class="rc-box-col-2" />
        <div class="rc-box-col-2"><img src="@/assets/2019/circlechek_logo.png" height="50px"></div>
        <div class="rc-box-col-9">
        
          <div v-if="hasCompany" class="rc-container rc-text-small rc-text-label">
            <Row>
              <div class="rc-box-col-9 rc-text-xxlarge rc-text-centre">
                <router-link to="/portal/operator/companys" v-if="hasCompanys" class="" onclick="w3_close()" >
                  {{ companyName }}
                </router-link>
                <div v-else>{{ companyName }} </div>
              </div>
              <div class="rc-box-col-5 ">
                <div class="rc-text-small rc-text-right">
                  <router-link to="/portal/profile" class="" onclick="w3_close()">  {{ first() }} ({{ email() }}) </router-link>
                </div>
              </div>
              <Column :width="1" class="rc-text-right">
                <p class="clickable" v-on:click="signout">Sign Out</p>
              </Column>
            </Row>
          </div>
          
          <div v-else class="rc-container rc-text-small rc-text-label">
            <div class="rc-row">
              <div class="rc-box-col-13 rc-text-small rc-text-label">
              </div>
              <div class="rc-box-col-2 rc-text-small rc-text-label rc-text-right">
              </div>
            </div>
          </div>
        
        </div> <!-- col-13 -->
        <div class="rc-box-col-2" />
      </div> <!-- row -->
      
      <div class="rc-row">
        <div class="rc-box-col-15">&nbsp;</div>
      </div>

      <div class="rc-row" v-if="hasCompany">
        <div class="rc-box-col-15 w3-right">
          <!-- Right-sided nav bar links -->
            <div v-if="isAdmin">
              <AppAdminMenu />
            </div>
            <div v-else>
              <AppPortalMenu />
            </div>
        </div>
      </div>
      
    </div><!-- OldMenu Container -->
    
    <Row>
      <Column :width="5">
        <Box>
        </Box>
      </Column>
      <Column :width="10">
      </Column>
    </Row>
      
  </div> <!-- Top -->

  
  <!-- Side bar on small screens when clicking the menu icon -->
  <nav class="w3-sidebar w3-bar-block w3-black w3-card w3-animate-left w3-hide-medium w3-hide-large" style="display:none" id="mySidebar">
    <div v-if="!isConnected">
      <AppMenu :isSmall="true" />
    </div>
    <div v-else>
      <div v-if="isAdmin">
        <AppAdminMenu />
      </div>
      <div v-else>
        <AppPortalMenu />
      </div>
    </div>
  </nav>
  
</div>
</template>

<script>

import { mapGetters } from 'vuex';
import AppMenu from './AppMenu.vue';
import AppPortalMenu from './AppPortalMenu.vue';
import AppAdminMenu from '@/portals/admin/AppAdminMenu.vue';
import ContentUtils from '@/utils/ContentUtils.js';
import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";
//import Button from "@/portals/shared/library/button/Button.vue";
import SignOutDialog from "@/portals/customer/operator/views/sign/out/SignOutDialog.vue";

export default {
  name: 'app-header',
  components: {
    Box, Row, Column,
    AppMenu,
    AppPortalMenu,
    AppAdminMenu,
    SignOutDialog,
  },
  data() {
    return {
      connected: false,
      hasCompany: false,
      companyName: null,
      isAdmin: false,
      isProduction: true,
      showSignOut: false,
    };
  },
  computed: {
    ...mapGetters([
                   'user_roles',
                   'auth_client',
                   'auth_connected',
                   'auth_user',
                   'AuthStorage_Company',
                   'domain',
      ]),
    isConnected: function() {
      return this.connected;
    },
    hasCompanys: function() {
      var user = this.domain.session().user();
      var employees = this.domain.employees().findByUserId(user.id());
      return employees.length() > 1;
    },
  },
  watch: {
    AuthStorage_Company() {
      if (this.AuthStorage_Company) {
        this.companyName = this.AuthStorage_Company.name();
        this.hasCompany = true;
      } else {
        this.companyName = null;
        this.hasCompany = false;
      }
      this.isAdmin = this.domain.session().employee().find().roles().find().isAdministrator();
      this.isProduction = !this.auth_client.debug;
      if (this.isAdmin) {
        this.companyName = "Administration Portal"
      }
    },
  },
  mounted: function() {
    this.isProduction = !this.auth_client.debug;
  },
  methods: {
    decode: function(value) {
      return ContentUtils.unescape(value);
    },
    first: function(){
      if (!this.domain || !this.domain.session()) {
        return "";
      }
      var name = this.domain.session().user().firstDisplay();
      return name;
    },
    email: function() {
      if (!this.domain || !this.domain.session()) {
        return "";
      }
      return this.domain.session().user().emailDisplay();
    },
    showOldMenu: function() {
      return true;
    },
    signout: function() {
      this.showSignOut = true;
    },
    closeSignOutDialog: function() {
     this.showSignOut = false;
    },
  },
}

</script>