<template>
  <div v-if="error" class="row">
    <div v-if="left" :class="leftPadding"> </div>
    <div class="rc-input-major w3-padding-small"
        :class="middle" >
      <div class="rc-font-small">There appears to be a problem:</div>
        {{ MC.error(error).value() }} 
      <div class="rc-font-tiny">({{ error.messageId }})</div>
    </div>
    <div v-if="right" :class="rightPadding"> </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MC from "@/domain/session/MC.js";

export default {
  name: 'error-row',
  components: {
  },
  props: {
    error: Object,
    left:  { type: Number, default : 0 },
    right: { type: Number, default : 0 },
  },
  data() {
    return {
      MC: new MC(),
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    leftPadding: function() {
      if (this.left > 0) {
        return "rc-box-col-" + this.left;
      }
      return "";
    },
    rightPadding: function() {
      if (this.right > 0) {
        return "rc-box-col-" + this.right;
      }
      return "";
    },
    middle: function() {
      var value = (15 - this.left - this.right);
      return "rc-box-col-" + value;
    },
  }
}
</script>