/*import Vue from 'vue'*/
import EventUtils from '@/utils/EventUtils.js'
import MapUtils from '@/utils/MapUtils.js'
import ConstUtils from '@/utils/ConstUtils.js'

const state = {
  list: [],
  map: {},
  total: 0,
  inspections_event: null,
  inspection_data: null,
}

const getters = {
  inspections_found: state => state,
  inspections_returned_event: state => state.inspections_event,
  inspection_data_event: state => state.inspection_data,
}

const actions = {
  InspectionsStore_clear({ commit }) {
    commit('clearInspections');
  }
}

const mutations = {
  clearInspections(state) {
    state.map = {};
    state.list = [];
  },
  SOCKET_ONMESSAGE(state, event) {
    if (event.status == EventUtils.STATUS.COMPLETE && event.statusCode == EventUtils.CODE.OK) {
      if (event.Payload && event.Payload.VehicleInspectionMap) {
        var inspections = event.Payload.VehicleInspectionMap;

        state.map = MapUtils.updateMap(inspections, state.map);
        state.list = Object.values(state.map);
        
        if (event.Payload.count) {
          state.total = event.Payload.count;
        }
      }
    }
    if (event.Payload && event.Payload.VehicleInspection) {
      var obj = event.Payload.VehicleInspection;
      state.map[obj[ConstUtils.FIELDS.ID]] = obj;
      state.list = Object.values(state.map);
    }
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
