/*import Vue from 'vue'*/
import EventUtils from '@/utils/EventUtils.js'
import MapUtils from '@/utils/MapUtils.js'
import ConstUtils from '@/utils/ConstUtils.js';

const state = {
  list: [],
  map: {},
  stats: {
    map: {},
    list: [],
  },
}

const getters = {
  companys_found: state => state,
  company_stats: state => state.stats,
}

const actions = {}

const mutations = {
  SOCKET_ONMESSAGE(state, event) {
    if (event.status == EventUtils.STATUS.COMPLETE && event.statusCode == EventUtils.CODE.OK) {
      if (event.Payload && event.Payload.CompanyMap) {
        var companys = event.Payload.CompanyMap
        state.map = MapUtils.updateMap(companys, state.map)
        state.list = Object.values(state.map)
      }
      if (event.Payload && event.Payload.Company) {
        var one = event.Payload.Company;
        state.map[one[ConstUtils.FIELDS.ID]] = one;
        state.list = Object.values(state.map);
      }
      if (event.Payload && event.Payload.CompanyStatsMap) {
        var statsMap = event.Payload.CompanyStatsMap;
        state.stats.map = MapUtils.updateMap(statsMap, state.stats.map);
        state.stats.list = Object.values(state.stats.map);
      }
      if (event.Payload && event.Payload.CompanyStats) {
        var stats = event.Payload.CompanyStats;
        state.stats.map[stats.Company[ConstUtils.FIELDS.ID]] = stats;
        state.stats.list = Object.values(state.stats.map);
      }
    }

  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
