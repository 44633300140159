export default class MapUtils {
    
  static updateMap(src, dest) {
    var keys = Object.keys(src);
    for (var i = 0; i < keys.length; i++) {
      var item = src[keys[i]];
      dest[keys[i]] = item;
    }
    return dest;
  }
  
}