
import RcDomainMap from "../../../../common/RcDomainMap.js";
import ChecklistItemResult from "./ChecklistItemResult.js";
import ChecklistItemResultUtils from "./ChecklistItemResultUtils.js";

export default class ChecklistItemResultMap extends RcDomainMap {
  
  static MODEL_NAME = "CheckListItemResultMap";

  constructor(domain, data) {
    super(domain, data, ChecklistItemResultMap.MODEL_NAME);
  }
  
  find() {
    var keys = this.keys();
    var found = new ChecklistItemResultMap(this.domain, {} );
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getChecklistItemResult(key);
      if (item.isNew()) {
        found.add(item);
      } else {
        var data = this.domain.checklists().items().results().findById(key);
        item = new ChecklistItemResult(this.domain, data);
        found.add(item);
      }
    }
    
    return found;
  }
  
  populate() {
    var found = this.find();
    this.addAll(found);
    var list = this.list();
    for (var i = 0; i < list.length; i++) {
      var item = list[i];
      item.populate();
    }
    return this
  }
  
  list() {
    var values = [];
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getChecklistItemResult(key);
      values.push(item);
    }
    return values;
  }
  
  sortByIndex() {
    var list = this.list();
    var fnc = ChecklistItemResultUtils.SortByIndex;
    var sorted = list.sort(fnc);
    return sorted;
  }
  
  findForItem(item) {
    var found = new ChecklistItemResultMap(this.domain, {} );
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var itemResult = this.getChecklistItemResult(key);
      if (itemResult.item().idEquals(item)) {
        found.add(itemResult);
      }
    }
    
    return found;
  }
  
  initial() {
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var result = this.getChecklistItemResult(key);
      if (result.isInitial()) {
        return result
      }
    }
    return new ChecklistItemResult(this.domain)
  }
  
  enroute() {
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var result = this.getChecklistItemResult(key);
      if (result.isEnroute()) {
        return result
      }
    }
    return new ChecklistItemResult(this.domain)
  }
  
  first() {
    var all = this.list();
    if (all.length > 0) {
      return all[0];
    }
    return new ChecklistItemResult(this.domain);
  }
  
  getChecklistItemResult(key) {
    var value = super.get(key);
    return new ChecklistItemResult(this.domain, value);
  }
  
  copy() {
    var data = this._copy();
    return new ChecklistItemResultMap(this.domain, data);
  }
  
}