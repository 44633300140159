
export default class EmployeeUtils {
  
  static SortByUserFirst = function(a,b) {
    var aUser = a.user().find();
    var bUser = b.user().find();
    if (aUser.first().toLowerCase() > bUser.first().toLowerCase()) {
      return 1;
    }
    if (aUser.first().toLowerCase()  < bUser.first().toLowerCase()) {
      return -1;
    }
    return 0;
  }
  
}