<template>

  <div class="rc-container" :class="additional()">
    <slot />
  </div>

</template>

<script>

import { mapGetters } from 'vuex'
import StringUtils from "@/utils/StringUtils.js";

export default {
  name: 'portals-shared-library-box-box',
  props: {
    showBorder: { type: Boolean, default: false },
    borderStyle: { type: String, default: "" },
    cmpt:       { type: String, default: "box" },
    backgroundColour: { type: String, default: "" },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
                 ]),
  },
  methods: {
    additional: function() {
      var value = "";
      if (this.showBorder) {
        value = "rc-border-thin ";
        if (!StringUtils.isEmpty(this.borderStyle)) {
          value = "rc-border-thin-" + this.borderStyle + "-arch ";
        }
      }
      if (!StringUtils.isEmpty(this.backgroundColour)) {
        value += "rc-" + this.backgroundColour + " ";
      }
      return value;
    },
  },
}
</script>