

export default class CanadianEnglish {
  
  static KEYS = {
      Home: {
        STATS_TITLE: 'HomeStatsTitle',
        WHATSNEW_TITLE: 'HomeStatsWhatsNewTitle',
        MONTHLY_TITLE: 'HomeStatsMonthlyTitle',
        COUNTS_TITLE: 'HomeStatsCountsTitle',
        WHATSNEW_V2_0_TITLE: 'HomeStatsV20Title',
        WHATSNEW_V150_TITLE: 'HomeStatsV150Title',
        WHATSNEW_V1504_TITLE: 'HomeStatsV1504Title',
        WHATSNEW_V250_TITLE: 'HomeStatsV250Title',
        WHATSNEW_V251_TITLE: 'HomeStatsV251Title',
      },
      
      COMPANY: {
        SAVE_SUCCESS: 'COMPANY_SAVE_SUCCESS',
        NAME:         'COMPANY_NAME',
        COUNTRY:      'COMPANY_COUNTRY',
        POSTAL:       'COMPANY_POSTALCODE',
        WEBSITE:      'COMPANY_WEBSITE',
        PROVINCE:     'COMPANY_PROVINCE',
      },
      
      BILLING: {
        DETAILS_TITLE: 'BILLING_DETAILS_TITLE',
        ADDRESS_TITLE: 'BILLING_ADDRESS_TITLE',
        CONTACT_NAME:  'BILLING_CONTACT_NAME',
        CONTACT_PHONE: 'BILLING_CONTACT_PHONE',
        CONTACT_EMAIL: 'BILLING_CONTACT_EMAIL',
        COMPANY_NAME:  'BILLING_COMPANY_NAME',
        EMAIL_TO:      'BILLING_EMAIL_TO',
        INVOICES_TITLE:'BILLING_INVOICES_TITLE',
        DETAILS_ACTION_TITLE: 'BILLING_DETAILS_ACTION_TITLE',
        APPEARS_HERE: 'BILLING_APPEARS_HERE',
      },
      
      INVOICE: {
        SETTINGS: {
          SAVE_SUCCESS: 'INVOICE_SETTINGS_SAVE_SUCCESS',
        }
      },
      
      SIGN: {
        IN: {
          TITLE: 'SIGN_IN_TITLE',
        },
        UP: {
          MESSAGE: 'SignUpMessage',
          MESSAGE2: 'SignUpMessage2',
          TITLE: 'SignUpTitle',
          BUTTON: 'SignUpButton',
          USER_TITLE: 'SignUpUserTitle',
          EMAIL: 'SignUpEmail',
          FIRST: 'SignUpFirst',
          LAST: 'SignUpLast',
          PHONE: 'SignUpPhone',
          COMPANY_TITLE: 'SignUpCompanyTitle',
          COMPANY: 'SignUpCompany',
          CITY: 'SignUpCity',
          PROVINCE: 'SignUpProvince',
          CODE: 'SignUpCode',
          ROLE: 'SignUpRole',
          TERMS_TITLE: 'SignUpTermsTitle',
          TERMS_MESSAGE: 'SignUpTermsMessage',
          TERMS_ADDRESS_LABEL: 'SignUpTermsAddressLabel',
          PROMO_CODE_TITLE: 'SignUpPromoCodeTitle',
          FEATURES_TITLE: 'SignUpFeaturesTitle',
          FEATURE_HOURS_OF_SERVICE: 'SignUpFeatureHoursOfService',
          FEATURE_CHECK_IN: 'SignUpFeatureCheckIn',
          FEATURE_INSPECTION: 'SignUpFeatureInspection',
          ADS: 'SignUpAds',
          HOWD_YOU_HEAR_ABOUT_US: 'SignUpHowdYouHearAboutUs',
          FLEET_SIZE: 'SignUpFleetSize',
        },
        OUT: {
          TITLE: 'SIGN_OUT_TITLE',
        }
      },
      
      SETTINGS: {
        MENU_TITLE:               'SETTINGS_MENU_TITLE',
        INSPECTION_EMAILTO_TITLE: 'SETTING_INSPECTION_EMAILTO_TITLE',
        INSPECTION_EMAILTO_HELP:  'SETTING_INSPECTION_EMAILTO_HELP',
        
        INVOICE_EMAILTO_HELP:     'SETTING_INVOICE_EMAILTO_HELP',
        INVOICE_EMAILTO_TITLE:    'SETTING_INVOICE_EMAILTO_TITLE',
        
        ADDRESS_TITLE: 'SETTING_ADDRESS_TITLE',
      },
      
      VEHICLE: {
        NAME: 'VEHICLE_NAME',
        DETAILS: 'VEHICLE_DETAILS',
        ODOMETER_FIELD_TITLE: 'VEHICLE_ODOMETER_FIELD_TITLE',
        HUBMETER_FIELD_TITLE: 'VEHICLE_HUBMETER_FIELD_TITLE',
        
        CATEGORY_COLUMN_TITLE: 'VEHICLE_CATEGORY_COLUMN_TITLE',
        NAME_COLUMN_TITLE: 'VEHICLE_NAME_COLUMN_TITLE',
        STATUS_COLUMN_TITLE: 'VEHICLE_STATUS_COLUMN_TITLE',
        BRAKETYPE_COLUMN_TITLE: 'VEHICLE_BRAKETYPE_COLUMN_TITLE',
        ODOMETER_COLUMN_TITLE: 'VEHICLE_ODOMETER_COLUMN_TITLE',
        PLATE_COLUMN_TITLE: 'VEHICLE_PLATE_COLUMN_TITLE',
        ANNUAL_COLUMN_TITLE: 'VEHICLE_ANNUAL_COLUMN_TITLE',
        MAKER_COLUMN_TITLE: 'VEHICLE_MAKER_COLUMN_TITLE',
        VIN_COLUMN_TITLE: 'VEHICLE_VIN_COLUMN_TITLE',
        
        ADD_HOW_TO: 'VEHICLE_ADD_HOW_TO',
        CATEGORY_FIELD_TITLE: 'VEHICLE_CATEGORY_FIELD_TITLE',
        STATUS_FIELD_TITLE: 'VEHICLE_STATUS_FIELD_TITLE',
        BRAKETYPE_FIELD_TITLE: 'VEHICLE_BRAKETYPE_FIELD_TITLE',
        NAME_FIELD_TITLE: 'VEHICLE_NAME_FIELD_TITLE',
        ATTRIBUTES_FIELD_TITLE: 'VEHICLE_ATTRIBUTES_FIELD_TITLE',
        COLOUR_FIELD_TITLE: 'VEHICLE_COLOUR_FIELD_TITLE',
        MAKER_FIELD_TITLE: 'VEHICLE_MAKER_FIELD_TITLE',
        MODEL_FIELD_TITLE: 'VEHICLE_MODEL_FIELD_TITLE',
        SCHEDULE_FIELD_TITLE: 'VEHICLE_SCHEDULE_FIELD_TITLE',
        VIN_FIELD_TITLE: 'VEHICLE_VIN_FIELD_TITLE',
        WEIGHT_FIELD_TITLE: 'VEHICLE_WEIGHT_FIELD_TITLE',
        YEAR_FIELD_TITLE: 'VEHICLE_YEAR_FIELD_TITLE',
        PLATE_FIELD_TITLE: 'VEHICLE_PLATE_FIELD_TITLE',
        PROVINCE_FIELD_TITLE: 'VEHICLE_PROVINCE_FIELD_TITLE',
        ANNUAL_FIELD_TITLE: 'VEHICLE_ANNUAL_FIELD_TITLE',
        ANNUALNUMBER_FIELD_TITLE: 'VEHICLE_ANNUALNUMBER_FIELD_TITLE',
        CATEGORY: 'VEHICLE_CATEGORY',
        PROVINCE: 'VEHICLE_PROVINCE',
        NO_RESULTS: 'VEHICLE_NO_RESULTS',
        REFRESHING: 'VEHICLE_REFRESHING',
        LOADING: 'VEHICLE_LOADING',
        LOADED: 'VEHICLE_LOADED',
        SCROLL: 'VEHICLE_SCROLL',
        LOAD_MORE: 'VEHICLE_LOAD_MORE',
        REMOVE_MESSAGE: 'VEHICLE_REMOVE_MESSAGE',
        INACTIVE_MESSAGE: 'VEHICLE_INACTIVE_MESSAGE',
      },
      ACTIONS: {
        SAVE: 'ACTION_SAVE',
        SAVE_CLOSE: 'ACTION_SAVE_CLOSE',
        REFRESH: 'ACTION_REFRESH',
        RESEND: 'ACTION_RESEND',
        ADD: 'ACTION_ADD',
        EDIT: 'ACTION_EDIT',
        TITLE: 'ACTION_TITLE',
        DETAILS: 'ACTION_DETAILS',
        ADD_VEHICLE: 'ACTION_ADD_VEHICLE',
        REMOVE: 'ACTION_REMOVE',
        RESTORE: 'ACTION_RESTORE',
        ADD_USER: 'ACTION_ADD_USER',
        CREATE_USER: 'ACTION_CREATE_USER',
        BACK: 'ACTION_BACK',
        ADD_COMPANY: 'ActionAddCompany',
      },
      FILTERS: {
        TITLE: 'FILTER_TITLE',
        STATUS: 'FILTER_STATUS',
        STATE: 'FILTER_STATE',
        TIME: 'FILTER_TIME',
        TYPE: 'FILTER_TYPE',
        ALL: 'FILTER_ALL',
        INVOICES: 'FILTER_INVOICES',
        DETAILS: 'FILTER_DETAILS',
        ACTIVE: 'FILTER_ACTIVE',
        INACTIVE: 'FILTER_INACTIVE',
        INVITED: 'FILTER_INVITED',
        VEHICLES: 'FILTER_VEHICLES',
        HOURS: 'FILTER_HOURS',
        TRAILERS: 'FILTER_TRAILERS',
        STATS: 'FILTER_STATS',
        WHATSNEW: 'FILTER_WHATSNEW',
        CURRENT_MONTH: 'FILTER_CURRENT_MONTH',
        TODAY: 'FILTER_TODAY',
        EMPLOYEES: 'FilterEmployees',
        USERS: 'FilterUsers',
        INSPECTIONS: 'FilterInspections',
        SETTINGS: 'FilterSettings',
        CHECKINS: 'FilterCheckIns',
        
      },
      INSPECTION: {
        LOADING: 'InspectionsLoading',
      }
  }
  
  static MESSAGES = {
      HomeStatsTitle: 'Stats',
      HomeStatsWhatsNewTitle: 'What\'s New!',
      HomeStatsMonthlyTitle: 'Created this month:',
      HomeStatsCountsTitle: 'Number of:',
      HomeStatsV20Title: 'V2.0.0 Released Oct 9, 2021',
      HomeStatsV250Title: 'V2.5.0 Released Dec 5, 2021',
      HomeStatsV251Title: 'V2.5.1 Released Dec 12, 2021',
      HomeStatsV150Title: 'V1.5.0 Released July 3, 2021',
      HomeStatsV1504Title: 'V1.5.04 Released July 17, 2021',
      
      ACTION_SAVE: "Save",
      ACTION_SAVE_CLOSE: "Save & Close",
      ACTION_REFRESH: 'Refresh',
      ACTION_RESEND: 'Reinvite',
      ACTION_ADD: 'Add',
      ACTION_EDIT: 'Edit',
      ACTION_TITLE: 'Actions',
      ACTION_DETAILS: 'Details',
      ACTION_REMOVE: 'Remove',
      ACTION_RESTORE: 'Restore',
      ACTION_ADD_VEHICLE: 'Add Vehicle or Trailer',
      ACTION_ADD_USER: 'Add User',
      ACTION_CREATE_USER: 'Create User',
      ACTION_BACK: 'Back',
      ActionAddCompany: 'New Company',
      
      INVOICE_SETTINGS_SAVE_SUCCESS: 'Invoice changes saved successfully',
      
      FILTER_TITLE:    'Filters',
      FILTER_STATUS:   'Status',
      FILTER_STATE:    'State',
      FILTER_TIME:     'Time',
      FILTER_TYPE:     'Type',
      FILTER_ALL:      'All',
      FILTER_INVOICES: 'Invoices',
      FILTER_DETAILS:  'Details',
      FILTER_INACTIVE: 'Removed',
      FILTER_ACTIVE:   'Active',
      FILTER_INVITED:  'Invited',
      FILTER_VEHICLES: 'Vehicles',
      FILTER_HOURS:    'Hours',
      FILTER_TRAILERS: 'Trailers',
      FILTER_WHATSNEW: 'Whats New',
      FILTER_STATS:    'Stats',
      FILTER_CURRENT_MONTH: 'Current Month',
      FILTER_TODAY:    'Today',
      FilterEmployees: 'Employees',
      FilterInspections: 'Inspections',
      FilterSettings:    'Billing',
      FilterUsers:  'Users',
      FilterCheckIns: 'Check Ins',
      
      BILLING_DETAILS_TITLE:  'Billing Details',
      BILLING_ADDRESS_TITLE:  'Address',
      BILLING_CONTACT_NAME:   'Contact Name',
      BILLING_CONTACT_PHONE:  'Contact Phone',
      BILLING_CONTACT_EMAIL:  'Contact Email',
      BILLING_COMPANY_NAME:   'Company Name',
      BILLING_EMAIL_TO:       'Email Invoices To',
      BILLING_INVOICES_TITLE: 'Invoices',
      BILLING_APPEARS_HERE:   'Invoices will appear here (Coming soon)',
      
      COMPANY_SAVE_SUCCESS: 'Company changes saved!',
      COMPANY_NAME: 'Company Name',
      COMPANY_COUNTRY: 'Country',
      COMPANY_POSTAL: 'Postal Code',
      COMPANY_WEBSITE: 'Website',
      COMPANY_PROVINCE: 'Province or State',
      
      SIGN_IN_TITLE: 'Sign In',
      
      SignUpTitle: 'Sign Up',
      SignUpCompanyTitle: 'Company Information',
      SignUpUserTitle: 'Your Information',
      SignUpFirst: 'First Name',
      SignUpLast: 'Last Name',
      SignUpEmail: 'Email',
      SignUpPhone: 'Phone #',
      SignUpCompany: 'Company Name',
      SignUpCity: 'City',
      SignUpProvince: 'Province or State',
      SignUpRole: 'Your role at this company',
      SignUpCode: 'Promo-code',
      SignUpButton: 'Send',
      SignUpMessage: 'Fill in the form below with your contact details and a little information about your company and our automated system will send you an email with sign in instructions.',
      SignUpMessage2: 'This may take up to 24 hrs',
      SignUpTermsTitle: 'Terms',
      SignUpTermsMessage: 'Click here to indicate that you have read and agree to the terms of the ',
      SignUpTermsAddressLabel: 'ReadyChek Software as a Service Agreement',
      SignUpPromoCodeTitle: 'Promo Code',
      SignUpFeaturesTitle: 'What would you like to use?',
      SignUpFeatureHoursOfService: 'Hours of Service',
      SignUpFeatureCheckIn: 'Check In',
      SignUpFeatureInspection: 'Vehicle Inspections',
      SignUpAds: "Social media, app store, other?",
      SignUpHowdYouHearAboutUs: "Please let us know how you found ReadyChek",
      SignUpFleetSize: "How many vehicles and trailers are in your fleet?",
        
      SIGN_OUT_TITLE: 'Sign Out',
      
      SETTINGS_MENU_TITLE: 'Filters',
      SETTING_ADDRESS_TITLE: 'Addresses',
      
      SETTING_INSPECTION_EMAILTO_TITLE: 'Inspection',
      SETTING_INSPECTION_EMAILTO_HELP: 'Email addresses in this list will receive a copy' +
        ' of the pretrip inspections when they are created.',
      
      SETTING_INVOICE_EMAILTO_TITLE: 'Invoice',
      SETTING_INVOICE_EMAILTO_HELP: 'Email addresses in this list will receive a copy' +
        ' of the invoice.',
      
      VEHICLE_NAME: 'Name',
      VEHICLE_DETAILS: 'Vehicle Details',
      VEHICLE_ODOMETER_FIELD_TITLE: 'Odometer',
      VEHICLE_HUBMETER_FIELD_TITLE: 'Hubmeter',
      VEHICLE_ADD_HOW_TO: 'On this page you can add Vehicles and Trailers,' + 
        ' each must have a break type.' + 
        ' To add a trailer select Trailer from the Vehicle Type / Trailer list.',
      VEHICLE_CATEGORY_FIELD_TITLE: 'Vehicle / Trailer Type',
      VEHICLE_BRAKETYPE_FIELD_TITLE: 'Brake Type',
      VEHICLE_NAME_FIELD_TITLE: 'Name (#34, Dave\'s Truck, Red)',
      VEHICLE_ATTRIBUTES_FIELD_TITLE: 'Attributes (Bucket, Drill, 5 Passenger)',
      VEHICLE_COLOUR_FIELD_TITLE: 'Colour',
      VEHICLE_MAKER_FIELD_TITLE: 'Make (Ford, International etc)',
      VEHICLE_MODEL_FIELD_TITLE: 'Model (F-550, Yukon)',
      VEHICLE_SCHEDULE_FIELD_TITLE: 'Vehicle Schedule',
      VEHICLE_VIN_FIELD_TITLE: 'VIN',
      VEHICLE_WEIGHT_FIELD_TITLE: 'Registered Gross Weight (kg)',
      VEHICLE_YEAR_FIELD_TITLE: 'Year',
      VEHICLE_PLATE_FIELD_TITLE: 'Plate',
      VEHICLE_PROVINCE_FIELD_TITLE: 'Province or State',
      VEHICLE_ANNUAL_FIELD_TITLE: 'Annual Date',
      VEHICLE_ANNUALNUMBER_FIELD_TITLE: 'Annual Number (H/S-1234567)',
      VEHICLE_CATEGORY: 'Type',
      VEHICLE_PROVINCE: 'Province or State',
      VEHICLE_NO_RESULTS: 'No results match search',
      VEHICLE_REFRESHING: 'Refreshing vehicles list...',
      VEHICLE_LOADING: 'Loading...',
      VEHICLE_LOADED: 'All vehicles and trailers have been loaded',
      VEHICLE_SCROLL: 'Scroll down to load more vehicles and trailers or ',
      VEHICLE_LOAD_MORE: 'Click here to load more',
      VEHICLE_REMOVE_MESSAGE: 'Are you sure you would like to remove \'{0}\'',
      VEHICLE_INACTIVE_MESSAGE: 'This vehicle is inactive',
      VEHICLE_NAME_COLUMN_TITLE: 'Name',
      VEHICLE_STATUS_COLUMN_TITLE: 'Status',
      VEHICLE_BRAKETYPE_COLUMN_TITLE: 'Brake Type',
      VEHICLE_ODOMETER_COLUMN_TITLE: 'Odometer',
      VEHICLE_PLATE_COLUMN_TITLE: 'Plate',
      VEHICLE_ANNUAL_COLUMN_TITLE: 'Annual',
      VEHICLE_MAKER_COLUMN_TITLE: 'Make',
      VEHICLE_VIN_COLUMN_TITLE: 'VIN',
      VEHICLE_CATEGORY_COLUMN_TITLE: 'Type',
      
      InspectionsLoading: 'Loading',
  }
  
  static Timesheet = class Timesheet {
    
    constructor() {
      this.LOADING = this.entry("Loading");
    }
    
    entry(string) {
      var length = CanadianEnglish.MESSAGES.length;
      var key = "Timesheet." + length;
      CanadianEnglish.MESSAGES[key] = string; 
    }
  }

  static Global = class Global {
    
    constructor() {
      this.VERSION = this.entry("1.6.2");
    }
    
    entry(string) {
      var length = CanadianEnglish.MESSAGES.length;
      var key = "Global." + length;
      CanadianEnglish.MESSAGES[key] = string; 
    }
  }
  
  constructor() {
  }
  
  get(id, hint) {
    var value = CanadianEnglish.MESSAGES[id];
    if (!value) {
      value = id;
      if (hint) {
        value = hint;
      }
    }
    if (!value) {
      value = id;
    }
    
    return value;
        
  }
}