

export default class CheckListItemOptionUtils {
  
  static SortByCreatedDate = function(a, b) {
    if (!a && !b) {
      return 0;
    }
    if (a.createdDate() < b.createdDate()) {
      return -1;
    }
    if (a.createdDate() > b.createdDate()) {
      return 1;
    }
    return 0;
  }
  
  static SortByIndex = function(a, b) {
    return a.index() - b.index();
  }
  
}