
import RcDomainMap from "@/domain/common/RcDomainMap.js";

import Note from './Note.js';
import NoteUtils from './NoteUtils.js';

export default class NoteMap extends RcDomainMap {
  
  static MODEL_NAME = "VehicleScheduleQuestionNoteMap";
  
  constructor(domain, data) {
    super(domain, data);
  }

  find() {
    var keys = this.keys();
    var found = new NoteMap(this.domain, {} );
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var data = this.domain.notes().findById(key);
      var item = new Note(this.domain, data);
      if (!item.isNew()) {
        found.add(item);
      }
    }
    
    return found;
  }
  
  list() {
    var values = [];
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getNote(key);
      values.push(item);
    }
    return values;
  }
  
  sort() {
    var sorted = [];
    sorted = this.list();
    sorted = sorted.sort(NoteUtils.SortByPosition);
    return sorted;
  }
  
  getNote(key) {
    var value = super.get(key);
    return new Note(this.domain, value);
  }
  
  copy() {
    var data = this._copy();
    return new NoteMap(this.domain, data);
  }
}