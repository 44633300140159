
import RcDomainObject from "../../../common/RcDomainObject";
import Company from "../../company/Company.js";
import Employee from "../../employee/Employee.js";
import ChecklistItemResultMap from "../item/result/ChecklistItemResultMap.js";

//import CheckListItemMap from "./item/CheckListItemMap.js";
import CheckList from "@/domain/model/checklist/CheckList.js";
import Vehicle from "@/domain/model/vehicle/Vehicle.js";
//import ConstUtils from '@/utils/ConstUtils.js'

export default class ChecklistResult extends RcDomainObject {
  
  static MODEL_NAME = 'CheckListResult';
  
  static Fields = {
      CHECKLIST: CheckList.MODEL_NAME,
      COMPANY: Company.MODEL_NAME,
      VEHICLE: Vehicle.MODEL_NAME,
      EMPLOYEE: Employee.MODEL_NAME,
      CHECKLIST_ITEM_RESULTS: ChecklistItemResultMap.MODEL_NAME,
  }
  
  constructor(domain, data) {
    super(domain, data, ChecklistResult.MODEL_NAME);
  }

  fields() {
    return ChecklistResult.FIELDS;
  }
  
  find() {
    var result = this.domain.checklists().findById(this.id());
    if (result.id() !== this.id()) {
      result = this;
    }
    return result;
  }

  checklist() {
    var data = this.get(ChecklistResult.Fields.CHECKLIST);
    return new CheckList(this.domain, data);
  }
  
  vehicle() {
    var data = this.get(ChecklistResult.Fields.VEHICLE);
    return new Vehicle(this.domain, data);
  }
  
  itemResults() {
    var data = this.get(ChecklistResult.Fields.CHECKLIST_ITEM_RESULTS);
    return new ChecklistItemResultMap(this.domain, data);
  }
  
  pointer() {
    var data = {'@rid': this.id()};
    var result = new ChecklistResult(this.domain, data);
    return result;
  }
  
  copy() {
    var data = this._copy();
    return new ChecklistResult(this.domain, data);
  }
}