

export default class ChecklistItemResultUtils {
  
  static SortByCreatedDate = function(a, b) {
    if (!a && !b) {
      return 0;
    }
    if (a.createdDate() < b.createdDate()) {
      return -1;
    }
    if (a.createdDate() > b.createdDate()) {
      return 1;
    }
    return 0;
  }
  
  static SortByIndex = function(a, b) {
    var aindex = a.index();
    var bindex = b.index();
    return aindex - bindex;
  }
  
}