
import RcDomainMap from "../../../common/RcDomainMap.js";

import ChecklistResult from "./ChecklistResult.js";
import ChecklistResultUtils from "./ChecklistResultUtils.js";

export default class ChecklistResultMap extends RcDomainMap {
  
  static MODEL_NAME = "CheckListResultMap";

  constructor(domain, data) {
    super(domain, data, ChecklistResultMap.MODEL_NAME);
  }
  
  find() {
    var keys = this.keys();
    var found = new ChecklistResultMap(this.domain, {} );
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getChecklistResult(key);
      if (item.isNew()) {
        found.add(item);
      } else {
        var data = this.domain.checklists().results().findById(key);
        item = new ChecklistResult(this.domain, data);
        if (key === item.id()) {
          found.add(item);
        }
      }
    }
    
    return found;
  }
  
  findResultForChecklist(vehicle, checklist) {
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var result = this.getChecklistResult(key);
      if (result.checklist().idEquals(checklist) && 
          result.vehicle().idEquals(vehicle)) {
        return result;
      }
    }
    return ChecklistResult(this.domain);
  }
  
  populate() {
    var found = this.find();
    this.addAll(found);
    var list = this.list();
    for (var i = 0; i < list.length; i++) {
      var item = list[i];
      item.populate();
    }
    return this
  }
  
  list() {
    var values = [];
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getChecklistResult(key);
      values.push(item);
    }
    return values;
  }
  
  sortByIndex() {
    var list = this.list();
    var fnc = ChecklistResultUtils.SortByIndex;
    var sorted = list.sort(fnc);
    return sorted;
  }
  
  first() {
    var all = this.list();
    if (all.length > 0) {
      return all[0];
    }
    return new ChecklistResult(this.domain);
  }
  
  getChecklistResult(key) {
    var value = super.get(key);
    return new ChecklistResult(this.domain, value);
  }
  
  copy() {
    var data = this._copy();
    return new ChecklistResultMap(this.domain, data);
  }
  
}