import ContentUtils from '@/utils/ContentUtils.js';

export default class DateUtils {
    
  static august12021() {
    return 1627790400000;
  }
  
  static yearInDays() {
    return 365;
  }
  
  static toDateTime(time) {
    return ContentUtils.decodeDateTime(time);
  }
  
  static toDate(time) {
    return ContentUtils.decodeDate(time);
  }
  
  static endOfLastMonth() {
    var today = new Date();
    var year = today.getFullYear();
    return DateUtils.lastDayOfMonth(today.getMonth() - 1, year);
  }
  
  static startOfLastMonth() {
    var today = new Date();
    var year = today.getFullYear();
    return DateUtils.firstDayOfMonth(today.getMonth() -1, year);
  }
  
  static endOfToday() {
    var today = new Date();
    today.setHours(23,59,59, 999);
    return today;
  }
  
  static startOfToday() {
    var today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  }
  
  static startOfMonth() {
    var today = new Date();
    var year = today.getFullYear();
    var firstOfMonth = DateUtils.firstDayOfMonth(today.getMonth(), year);
    return firstOfMonth;
  }
  
  static firstDayOfMonth(month, year) {
    var first = new Date(year, month, 1);
    first.setHours(0, 0, 0, 0);
    return first;
  }
  
  static lastDayOfMonth(month, year) {
    var last = new Date(year, month + 1, 0);
    last.setHours(23, 59, 59, 999);
    return last;
  }
  
  static daysFromMs(time) {
    return time/DateUtils.days(1);
  }
  
  static days(number) {
    return number * 24 * 60 * 60 * 1000;
  }
  
  static days30() {
    return DateUtils.days(30);
  }
  
  static hours24() {
    return 24 * 60 * 60 * 1000;
  }
  
  static now() {
    return Date.now();
  }
  
  static weeksAgo(numberOfWeeks) {
    var aWeek = 7 * DateUtils.hours24();
    return aWeek * numberOfWeeks;
  }
  
  static JANUARY = 0;
  static FEBRUARY = 1;
  static MARCH = 2;
  static APRIL = 3;
  static MAY = 4;
  static JUNE = 5;
  static JULY = 6;
  static AUGUST = 7;
  static SEPTEMBER = 8;
  static OCTOBER = 9;
  static NOVEMBER = 10;
  static DECEMBER = 11;
  
  
  static daysInMonth(year, month) {
    if (year) {
      //
    }
    var days = 30;
    switch (month) {
      case DateUtils.JANUARY:
      case DateUtils.MARCH:
      case DateUtils.MAY:
      case DateUtils.JULY:
      case DateUtils.AUGUST:
      case DateUtils.OCTOBER:
      case DateUtils.DECEMBER:
        days = 31;
        break;
      
      case DateUtils.FEBRUARY: 
        days = 28;
        break;
      default:
        days = 30;
        break;
    }
    return days;
  }
  
  static monthName(month, abbreviated) {
    const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];

    var name = months[month];
    if (abbreviated) {
      name = name.substring(0,3);
    }
    return name;
  }
}