
import RcDomainObject from "./RcDomainObject";


export default class RcDomainMap extends RcDomainObject {

  constructor(domain, data, name) {
    super(domain, data);
    this._modelName = name;
    if (!data) {
      this.data = {};
    }
    delete this.data['@rid'];
  }
  
  modelName() {
    return this._modelName;
  }
  
  addAll(map) {
    var keys = map.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = map.get(key);
      this.add(item);
    }
    return this;
  }
  
  removeAll(map) {
    var keys = map.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = map.get(key);
      this.remove(item);
    }
    return this;
  }
  
}