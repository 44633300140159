

export default class ImageUtils {
  
  static SortByCreatedDate = function(a, b) {
    if (!a || !b) {
      return 0;
    }
    if (a.createdDate() < b.createdDate()) {
      return -1;
    }
    if (a.createdDate() > b.createdDate()) {
      return 1;
    }
    return 0;
  }
  
  static SortByIndex = function(a, b) {
    if (!a || !b) {
      return 0;
    }
    if (a.index() > b.index()) {
      return 1;
    }
    if (a.index() < b.index()) {
      return -1;
    }
    return 0;
  }
  
  static SortByIndexDesc = function(a, b) {
    if (!a || !b) {
      return 0;
    }
    if (a.index() > b.index()) {
      return -1;
    }
    if (a.index() < b.index()) {
      return 1;
    }
    return 0;
  }
}