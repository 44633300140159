
const state = {
    event: {},
};

const getters = {
    register_event : state => state.event,
};

const actions = {
};

const mutations = {
  SOCKET_ONMESSAGE (state, event)  {
    if (state && event) {
      //
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};