
import TimeUnit from "./TimeUnit.js";
//import StringUtils from "@/utils/StringUtils.js";
import DateUtils from "@/utils/DateUtils.js";
import RcYear from "./RcYear.js";
import RcDay from "./RcDay.js";

export default class RcMonth extends TimeUnit {
  
  constructor(date, timeZone) {
    super(date, timeZone);
  }
  
  firstDay() {
    var date = new Date(
        this.date().getFullYear(), 
        this.date().getMonth(), 
        1, 
        this.date().getHours(), 
        this.date().getMinutes(), 
        this.date().getSeconds(), 
        this.date().getMilliseconds() );
    
    return new RcDay(date, this.timeZone());
  }
  
  lastDay() {
    var year = this.date().getFullYear();
    var month = this.date().getMonth();
    var end = DateUtils.daysInMonth(year, month);
    
    var date = new Date(
        year, 
        month, 
        end, 
        this.date().getHours(), 
        this.date().getMinutes(), 
        this.date().getSeconds(), 
        this.date().getMilliseconds() );
    
    return new RcDay(date, this.timeZone());
  }
  
  previous() {
    var prev = this.value() - 1;
    var year = this.year().value();
    if (prev < DateUtils.JANUARY) {
      prev = DateUtils.DECEMBER;
      year = year - 1;
    }
    
    var date = new Date(
        year, 
        prev, 
        this.date().getDate(), 
        this.date().getHours(), 
        this.date().getMinutes(), 
        this.date().getSeconds(), 
        this.date().getMilliseconds() );
    
    return new RcMonth(date, this.timeZone());
  }
  
  next() {
    var next = this.value() + 1;
    var year = this.year().value();
    if (next > DateUtils.DECEMBER) {
      next = DateUtils.JANUARY;
      year = year + 1;
    }
    
    var date = new Date(
        year, 
        next, 
        this.date().getDate(),
        this.date().getHours(), 
        this.date().getMinutes(), 
        this.date().getSeconds(), 
        this.date().getMilliseconds() );
    
    return new RcMonth(date, this.timeZone());
  }
  
  day() {
    return new RcDay(this.date(), this.timeZone());
  }
  
  year() {
    return new RcYear(this.date(), this.timeZone());
  }
  
  value() {
    return this.date().getMonth();
  }
  
  name(abbreviated) {
    return DateUtils.monthName(this.value(), abbreviated);
  }
  
  plus(numberOfMonths) {
    var result = this;
    for (var i = 0; i < numberOfMonths; i++) {
      result = result.next();
    }
    return result
  }
}