
export default class UserUtils {
  
  static SortByFirst = function(a,b) {
    if (a.first() > b.first()) {
      return 1;
    }
    if (a.first() < b.first()) {
      return -1;
    }
    return 0;
  }
  
}