
export default class EventUtils {
  static STATUS = {
    COMPLETE: 'complete',
  }

  static CODE = {
    OK: '200',
    BAD_REQUEST: '400',
    FORBIDDEN: '403',
    NOTFOUND: '404',
  }

  static Send = {
    event: function(socket, event) {
      socket.sendObj(event);
    },
  }

}
