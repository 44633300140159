<style>
@import './assets/styles/w3.css';
@import './assets/styles/fontawesome.min.css';
@import './assets/styles/rc_main.css';
@import './assets/styles/rc_box.css';
@import './assets/styles/rc_text.css';
@import './assets/styles/rc_input.css';
@import './assets/styles/rc_font.css';
</style>
<template>
  <div id="app">
    <Box>
    
     <Row>
       <Column :width="15">
         <app-header />   
       </Column>
     </Row>
     <Row>
       
       <Column :width="2">
       </Column>
       
       <Column :showBorder="false" :width="11" style="">
         <router-view />
       </Column>
       
       <Column :width="2">
       </Column>
       
     </Row>
     <Row>
       <Column :width="2">
       </Column>
       <Column :width="11">
         <app-footer />
       </Column>
       <Column :width="2">
       </Column>
     </Row>
     
    </Box>
    
    
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import AppHeader from '@/views/app/AppHeader.vue';
import AppFooter from '@/views/app/AppFooter.vue';
import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

export default {
  name: 'app',
  components: {
    AppHeader,
    AppFooter,
    Box,Row,Column,
  },
  data() {
    return {
      error: null,
    };
  },
  computed: {
    ...mapGetters([
      'auth_user',
      'auth_client',
      'roles_found',
      'user_role',
    ]),
  },
  watch: {},
  methods: {},
};
</script>
