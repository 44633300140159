/*import Vue from 'vue'*/
import EventUtils from '@/utils/EventUtils.js';
import MapUtils from '@/utils/MapUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';

const state = {
  list: [],
  map: {},
};

const getters = {
    categorys_found: state => state,
};

const actions = {
};

const mutations = {
  SOCKET_ONMESSAGE (state, event)  {
    if (event.status == EventUtils.STATUS.COMPLETE && event.statusCode == EventUtils.CODE.OK) {
      if (event.Payload && event.Payload.VehicleCategoryMap) {
        var categorys = event.Payload.VehicleCategoryMap;
        state.map = MapUtils.updateMap(categorys, state.map);
        state.list = Object.values(state.map);
      }
      if (event.Payload && event.Payload.VehicleCategory) {
        var one = event.Payload.VehicleCategory;
        state.map[one[ConstUtils.FIELDS.ID]] = one;
        state.list = Object.values(state.map);
      }
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};