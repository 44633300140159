/*import Vue from 'vue'*/
//import EventUtils from '@/utils/EventUtils.js';
//import MapUtils from '@/utils/MapUtils.js';

const state = {
  list: [],
  map: {},
  listeners: {},
};

const getters = {
    events_store: state => state,
};

const actions = {
  addListenerAction ({ commit, state }, payload) {
    if (commit && state) {
      commit('addListener', payload);
    }
  },
  removeListenerAction ({ commit, state }, eventName) {
    if (commit && state) {
      commit('removeListener', eventName);
    }
  },
};

const mutations = {
  SOCKET_ONMESSAGE(state, event) {
    if (state && event) {
      var id = event['@rid'];
      var listener = state.listeners[id];
      if (listener) {
        if (!listener.emit(event)) {
          delete state.listeners[id];
        }
      }
    }
  },
  addListener(state, payload) {
    if (state) {
      state.listeners[payload.eventName] = payload.listener;
    }
  },
  removeListener(state, eventName) {
    if (state) {
      delete state.listeners[eventName];
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};