
import TimeUnit from "./TimeUnit.js";
import DateUtils from "@/utils/DateUtils.js";
import RcMonth from "./RcMonth.js";
import RcDay from "./RcDay.js";
import RcYear from "./RcYear.js";

export default class RcDate extends TimeUnit {
  
  constructor(date, timeZone) {
    super(date, timeZone);
  }
  
  day() {
    return new RcDay(this.date(), this.timeZone());
  }
  
  month() {
    return new RcMonth(this.date(), this.timeZone());
  }
  
  year() {
    return new RcYear(this.date(), this.timeZone());
  }
  
  value() {
    return this.date().getTime();
  }
  
  name() {
    return this.date().getDay();
  }
  
  minus(days) {
    return new RcDate(new Date(this.value() - DateUtils.days(days)), this.timeZone());
  }
}