
const state = {
  allFilter: true,
  statsFilter: false,
  whatsNewFilter: false,
}

const getters = {
  allFilter : state => state.allFilter,
  statsFilter : state => state.statsFilter,
  whatsNewFilter : state => state.whatsNewFilter,
}

const actions = {
  allFilterToggle({ commit }) {
    commit("allFilterToggle");
  },
  statsFilterToggle({ commit }) {
    commit("statsFilterToggle");
  },
  whatsNewFilterToggle({ commit }) {
    commit("whatsNewFilterToggle");
  }
}

const mutations = {
  allFilterToggle(state) {
    state.allFilter = !state.allFilter;
    state.whatsNewFilter = false;
    state.statsFilter = false;
  },
  statsFilterToggle(state) {
    state.statsFilter = !state.statsFilter;
    state.whatsNewFilter = false;
    state.allFilter = false;
  },
  whatsNewFilterToggle(state) {
    state.whatsNewFilter = !state.whatsNewFilter;
    state.statsFilter = false;
    state.allFilter = false;
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
};