/*import Vue from 'vue'*/
import EventUtils from '@/utils/EventUtils.js';
import MapUtils from '@/utils/MapUtils.js';
import ConstUtils from '@/utils/ConstUtils.js'

//import RcPayload from '@/domain/model/events/RcPayload.js';
//import AddressMap from '@/domain/model/address/AddressMap.js';
//import Address from '@/domain/model/address/Address.js';

const state = {
  list: [],
  map: {},
};

const getters = {
    TimesheetStorage: state => state,
};

const actions = {
};

const mutations = {
  SOCKET_ONMESSAGE (state, event)  {
    if (event.status == EventUtils.STATUS.COMPLETE && event.statusCode == EventUtils.CODE.OK) {
      if (event.Payload && event.Payload.TimesheetMap) {
        var items = event.Payload.TimesheetMap;
        state.map = MapUtils.updateMap(items, state.map);
        state.list = Object.values(state.map);
      }
      if (event.Payload && event.Payload.Timesheet) {
        var item = event.Payload.Timesheet;
        state.map[item[ConstUtils.FIELDS.ID]] = item;
        state.list = Object.values(state.map);
      }
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};