
import RcDomainObject from "@/domain/common/RcDomainObject.js";
import OptionMap from "@/domain/model/schedule/question/option/OptionMap.js";

import Catalog from "@/domain/session/Catalog.js";

//import StringUtils from '@/utils/StringUtils.js';

export default class Question extends RcDomainObject {
  
  static MODEL_NAME = "VehicleScheduleQuestion";
  
  static Fields = {
    PART: 'part',
    VALUE: 'Value',
    OPTIONS: 'VehicleScheduleQuestionOptionMap',
    TYPE: "type",
  }
  
  static Values = {
      Type: {
        Text: "text",
        Input: "input",
      }
  }
  
  constructor(domain, data) {
    super(domain, data, Question.MODEL_NAME);
    this.C = new Catalog();
  }

  find() {
    return this.domain.questions().findById(this.id());
  }

  options() {
    return new OptionMap(this.domain, this.get(Question.Fields.OPTIONS));
  }
  
  type() {
    return this.getString(Question.Fields.TYPE);
  }
  
  withTypeText() {
    this.withType(Question.Values.Type.Text);
    return this;
  }
  
  withType(type) {
    this.put(Question.Fields.TYPE, type);
    return this;
  }
  
  name() {
    return this.getString(Question.Fields.VALUE);
  }
  
  part() {
    return this.getString(Question.Fields.PART);
  }
  
  withPart(part) {
    this.put(Question.Fields.PART, part);
    return this;
  }
  
  value() {
    return this.getString(Question.Fields.VALUE);
  }
}