<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="rc-box-col-1"><img src="@/assets/2019/circlechek_logo.png" height="50px"></div>
        <div class="rc-box-col-14">
          <div class="rc-font-medium rc-text-right">
            <div class="rc-text-label">
              {{ welcome() }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'app-menu',
  props: {
    isSmall: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters([
        'auth_user', 
        'auth_client',
      ]),
  },
  methods: {
    welcome: function() {
      return "Welcome Guest";
    }
  }
}
</script>