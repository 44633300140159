
import RcDomainObject from "@/domain/common/RcDomainObject.js";
import ChecklistItem from "@/domain/model/checklist/item/CheckListItem.js";
import ChecklistItemOptionMap from "@/domain/model/checklist/item/option/CheckListItemOptionMap.js";

//import CheckListItemMap from "./item/CheckListItemMap.js";
//import CheckListMap from "./CheckListMap.js";
import StringUtils from '@/utils/StringUtils.js'

export default class ChecklistItemResult extends RcDomainObject {
  
  static MODEL_NAME = 'CheckListItemResult';
  
  static Fields = {
      ITEM: ChecklistItem.MODEL_NAME,
      ITEM_OPTIONS: ChecklistItemOptionMap.MODEL_NAME,
      RESULT_TYPE: "resultType",
      REMARKS: "remarks",
      VALUE: "value",
  }
  
  static Values = {
      ResultTypes: {
        Initial: "initial",
        Enroute: "enroute",
      },
  }
  
  constructor(domain, data) {
    super(domain, data, ChecklistItemResult.MODEL_NAME);
  }

  fields() {
    return ChecklistItemResult.FIELDS;
  }
  
  find() {
    var result = this.domain.checklists().findById(this.id());
    if (result.id() !== this.id()) {
      result = this;
    }
    
    return result;
  }

  item() {
    var data = this.get(ChecklistItemResult.Fields.ITEM);
    return new ChecklistItem(this.domain, data);
  }
  
  options() {
    var data = this.get(ChecklistItemResult.Fields.ITEM_OPTIONS);
    return new ChecklistItemOptionMap(this.domain, data);
  }
  
  resultType() {
    return this.getString(ChecklistItemResult.Fields.RESULT_TYPE);
  }
  
  value() {
    return this.getString(ChecklistItemResult.Fields.VALUE);
  }
  
  remarks() {
    return this.getString(ChecklistItemResult.Fields.REMARKS);
  }
  
  hasRemarks() {
    return !StringUtils.isEmpty(this.remarks());
  }
  
  isInitial() {
    return StringUtils.e(ChecklistItemResult.Values.ResultTypes.Initial, this.resultType());
  }
  
  hasResultType() {
    return !StringUtils.isEmpty(this.resultType());
  }
  
  isEnroute() {
    return StringUtils.e(ChecklistItemResult.Values.ResultTypes.Enroute, this.resultType());
  }
  
  pointer() {
    var data = {'@rid': this.id()};
    var result = new ChecklistItemResult(this.domain, data);
    return result;
  }
  
  copy() {
    var data = this._copy();
    return new ChecklistItemResult(this.domain, data);
  }
}