import Vue from 'vue';
import VueCookie from 'vue-cookie';
import App from './App.vue';
import router from './router';
import store from './store';
import VueNativeSock from 'vue-native-websocket';
import UUID from 'vue-uuid';
import hashcode from 'hashcode';

Vue.use(UUID)
Vue.use(VueCookie)
Vue.use(hashcode)
Vue.use(VueNativeSock, process.env.VUE_APP_RC_API, { store: store, format: 'json', connectManually: true})

Vue.config.productionTip = false

const rcApp = new Vue({
  router,
  store,
  render: h => h(App)
})

rcApp.$mount('#app')
