/*import Vue from 'vue'*/
//import EventUtils from "@/utils/EventUtils.js";

const state = {
  changePassword: null,
  list: [],
  map: {},
  invited: null,
  added: null,
  removed: null,
};

const getters = {
  changepassword_event: (state) => state.changePassword,
};

const actions = {};

const mutations = {
};

export default {
  state,
  getters,
  actions,
  mutations,
};
