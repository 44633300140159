
import RcDomainObject from "../../common/RcDomainObject.js";


import StringUtils from '@/utils/StringUtils.js';
import Catalog from "@/domain/session/Catalog.js";

import QuestionMap from "./question/QuestionMap.js";
import User from "@/domain/model/user/User.js";
import Company from "@/domain/model/company/Company.js";

export default class Schedule extends RcDomainObject {
  
  static MODEL_NAME = "VehicleSchedule";
  
  static Fields = {
    NAME: 'name',
    QUESTIONS: "VehicleScheduleQuestionMap",
    DESCRIPTION: "description",
    DECLARATION: "declaration",
    NUMBER: "number",
    COMPANY: "Company",
  }
  
  constructor(domain, data) {
    super(domain, data, Schedule.MODEL_NAME);
    this.C = new Catalog();
  }

  find() {
    return this.domain.schedules().findById(this.id());
  }
  
  questions() {
    var map = new QuestionMap(this.domain, this.get(Schedule.Fields.QUESTIONS));
    return map
  }
  
  name() {
    return this.getString(Schedule.Fields.NAME);
  }
  
  createdBy() {
    var data = this.get(RcDomainObject.DefaultFields.CREATED_BY);
    return new User(this.domain, data);
  }
  
  modifiedBy() {
    var data = this.get(RcDomainObject.DefaultFields.MODIFIED_BY);
    return new User(this.domain, data);
  }
  
  company() {
    var data = this.get(Schedule.Fields.COMPANY);
    return new Company(this.domain, data);
  }
  
  description() {
    return this.getString(Schedule.Fields.DESCRIPTION);
  }
  
  declaration() {
    return this.getString(Schedule.Fields.DECLARATION);
  }
  
  number() {
    return this.getString(Schedule.Fields.NUMBER);
  }
  
  nameDisplay() {
    return StringUtils.format(
        this.C.get(Catalog.Inspection.DECLARATION_MSG), 
        [this.name()]);
  }
  
  pointer() {
    var schedule = new Schedule(this.domain, {});
    schedule.withId(this.id());
    return schedule;
  }
}