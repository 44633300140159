
import TimeUnit from "./TimeUnit.js";
import DateUtils from "@/utils/DateUtils.js";
import StringUtils from "@/utils/StringUtils.js";
import RcMonth from "./RcMonth.js";
import RcDay from "./RcDay.js";

export default class RcYear extends TimeUnit {
  
  constructor(date, timeZone) {
    super(date, timeZone);
  }
  
  firstMonth() {
    var date = new Date(
        this.date().getFullYear(), 
        DateUtils.JANUARY, 
        this.date().getDate(), 
        this.date().getHours(), 
        this.date().getMinutes(), 
        this.date().getSeconds(), 
        this.date().getMilliseconds() );
    
    return new RcMonth(date, this.timeZone());
  }
  
  lastMonth() {
    var date = new Date(
        this.date().getFullYear(), 
        DateUtils.DECEMBER, 
        this.date().getDate(),
        this.date().getHours(), 
        this.date().getMinutes(), 
        this.date().getSeconds(), 
        this.date().getMilliseconds() );
    
    return new RcMonth(date, this.timeZone());
  }
  
  previous() {
    
    var date = new Date(
        this.date().getFullYear() - 1, 
        this.date().getMonth(),
        this.date().getDate(),
        this.date().getHours(), 
        this.date().getMinutes(), 
        this.date().getSeconds(), 
        this.date().getMilliseconds() );
    
    return new RcYear(date, this.timeZone());
  }
  
  next() {
    var date = new Date(
        this.date().getFullYear() + 1, 
        this.date().getMonth(),
        this.date().getDate(),
        this.date().getHours(), 
        this.date().getMinutes(), 
        this.date().getSeconds(), 
        this.date().getMilliseconds() );
    
    return new RcYear(date, this.timeZone());
  }
  
  day() {
    return new RcDay(this.date(), this.timeZone());
  }
  
  value() {
    return this.date().getFullYear();
  }
  
  name() {
    return StringUtils.toString(this.value());
  }
  
  
}