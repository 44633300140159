
import RcDomainObject from "../../common/RcDomainObject";
//import Company from "../company/Company.js";
import ImageMap from "./ImageMap.js";
import StringUtils from '@/utils/StringUtils.js'

export default class Image extends RcDomainObject {

  static MODEL_NAME = 'Image';

  static Fields = {
    Index: "index",
    Image: "image",
    Url: "url",
    Data: "image",
  }

  constructor(domain, data) {
    super(domain, data, Image.MODEL_NAME);
  }

  hasUrl() {
    return !(StringUtils.isEmpty(this.url()));
  }

  url() {
    return this.getString(Image.Fields.Url);
  }

  withUrl(url) {
    this.put(Image.Fields.Url, url);
  }

  image() {
    return this.getString(Image.Fields.Image);
  }

  withData(data) {
    this.put(Image.Fields.Data, data);
    return this;
  }

  imageDisplay() {
    var display = "";
    if (this.hasUrl()) {
      var host = this.domain.client().cdnHost;
      display = host + "/companys/" + this.url();
    } else {
      display = "data:image/png;base64," + this.image();
    }
    return display;
  }

  index() {
    return this.getInt(Image.Fields.Index);
  }

  fields() {
    return Image.FIELDS;
  }

  find() {
    var result = this.domain.images().findById(this.id());
    if (result.id() !== this.id()) {
      result = this;
    }

    return result;
  }

  map() {
    var map = new ImageMap(this.domain);
    map.add(this);
    return map;
  }

  pointer() {
    var data = { '@rid': this.id() };
    var image = new Image(this.domain, data);
    return image;
  }

  copy() {
    var data = this._copy();
    return new Image(this.domain, data);
  }
}