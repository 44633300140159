
import RcDomainObject from "@/domain/common/RcDomainObject.js";
//import Vehicle from "@/domain/model/vehicle/Vehicle.js";

//import Catalog from "@/domain/session/Catalog.js";

//import StringUtils from '@/utils/StringUtils.js';
//import ConstUtils from '@/utils/ConstUtils.js';

export default class Note extends RcDomainObject {
  
  static MODEL_NAME = "VehicleScheduleQuestionNote";
  
  static Fields = {
    VALUE: 'value',
    POSITION: 'position',
  }
  
  static Values = {
      
  }
  
  constructor(domain, data) {
    super(domain, data, Note.MODEL_NAME);
  }

  find() {
    return this.domain.notes().findById(this.id());
  }
  
  position() {
    return this.getString(Note.Fields.POSITION);
  }
  
  value() {
    return this.getString(Note.Fields.VALUE);
  }
  
  
}