
import RcDomainObject from "../../common/RcDomainObject";
import Employee from "../employee/Employee.js";
import Company from "../company/Company.js";
import DateUtils from "@/utils/DateUtils.js";

export default class CheckIn extends RcDomainObject {
  
  static MODEL_NAME = 'CheckIn';
  
  static Fields = {
    EMPLOYEE: "Employee",
    RESULT: "result",
    CREATED_DATE: "createdDate",
    COMPANY: "Company",
  }
  
  constructor(domain, data) {
    super(domain, data, CheckIn.MODEL_NAME);
  }

  fields() {
    return CheckIn.FIELDS;
  }
  
  find() {
    return this.domain.checkins().findById(this.id());
  }

  
  company() {
    var data = this.get(CheckIn.Fields.COMPANY);
    var company = new Company(this.domain, data);
    return company;
  }
  
  employee() {
    var data = this.get(CheckIn.Fields.EMPLOYEE);
    var employee = new Employee(this.domain, data);
    return employee;
  }
  
  createdDateDisplay() {
    var data = this.get(CheckIn.Fields.CREATED_DATE);
    return DateUtils.toDateTime(data);
  }
  
  result() {
    var result = this.get(CheckIn.Fields.RESULT);
    return result;
  }
  
  isResultGood() {
    return this.result() === "None";
  }
  
  resultDisplay() {
    var result = this.result();
    if (result === "None") {
      return "Good";
    }
    return "Not able to work";
  }
  
  copy() {
    var data = this._copy();
    return new CheckIn(this.domain, data);
  }
}