/*import Vue from 'vue'*/
import EventUtils from '@/utils/EventUtils.js';
import MapUtils from '@/utils/MapUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';

const state = {
  list: [],
  map: {},
};

const getters = {
    types_found: state => state,
};

const actions = {
};

const mutations = {
  SOCKET_ONMESSAGE (state, event)  {
    if (event.status == EventUtils.STATUS.COMPLETE && event.statusCode == EventUtils.CODE.OK) {
      if (event.Payload && event.Payload.VehicleTypeMap) {
        var types = event.Payload.VehicleTypeMap;
        state.map = MapUtils.updateMap(types, state.map);
        state.list = Object.values(state.map);
      }
      if (event.Payload && event.Payload.VehicleType) {
        var type = event.Payload.VehicleType;
        state.map[type[ConstUtils.FIELDS.ID]] = type;
        state.list = Object.values(state.map);
      }
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};