
import DateUtils from "@/utils/DateUtils.js";

export default class TimeUnit {
  
  constructor(date, timeZone) {
    this._date = date;
    this._timeZone = timeZone;
  }
  
  date() {
    return this._date;
  }
  
  timeZone() {
    var tz = this._timeZone;
    return tz;
  }
  
  time() {
    return this._date.getTime();
  }
  
  isEmpty() {
    if (this._date.getTime() == 0) {
      return true;
    }
    return false;
  }
  
  isLt(timeUnit) {
    return this.time() < timeUnit.time();
  }

  isGt(timeUnit) {
    return this.time() > timeUnit.time();
  }

  displayAs_YYYY_MM_DD_HH_MM_SS_A(notSetValue) {
    if (this.isEmpty()) {
      return notSetValue ? notSetValue : "";
    }

    var month = this._date.getMonth() + 1;
    if (month + 1 < 10) {
      month = "0" + month;
    }
    var day = this._date.getDate();
    if (day < 10) {
      day = "0" + day;
    }
    
    var hour = this._date.getHours();
    if (hour > 12) {
      hour = hour - 12;
    }
    
    var minute = this._date.getMinutes();
    if (minute < 10) {
      minute = "0" + minute;
    }
    
    var seconds = this._date.getSeconds();
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    var timeZone = this.timeZone();
    var dateString = this._date.toLocaleTimeString(
        'en-ca',
        {timeZoneName:'short', timeZone: timeZone});
    
    return this._date.getFullYear() + '-' + month + '-' + day + " " + dateString;
  }
  
  displayAs_YYYY_MM_DD() {
    if (this._date.getTime() == 0) {
      return "";
    }

    var month = this._date.getMonth() + 1;
    if (month + 1 < 10) {
      month = "0" + month;
    }
    var day = this._date.getDate();
    if (day < 10) {
      day = "0" + day;
    }
    if (month.toString() === "NaN") {
      return "";
    }
    return this._date.getFullYear() + '-' + month + '-' + day;
  }
  
  displayAs_MM_DD(monthName) {
    if (this._date.getTime() == 0) {
      return "";
    }

    var month = this._date.getMonth() + 1;
    if (month + 1 < 10) {
      month = "0" + month;
    }
    if (monthName) {
      month = DateUtils.monthName(this._date.getMonth(), true);
    }
    
    var day = this._date.getDate();
    if (day < 10) {
      day = "0" + day;
    }
    if (month.toString() === "NaN") {
      return "";
    }
    return month + '-' + day;
  }
  
  displayAs_DD(monthName) {
    if (this._date.getTime() == 0) {
      return "";
    }

    var month = this._date.getMonth() + 1;
    if (month + 1 < 10) {
      month = "0" + month;
    }
    if (monthName) {
      month = DateUtils.monthName(this._date.getMonth(), true);
    }
    var day = this._date.getDate();
    if (day < 10) {
      day = "0" + day;
    }
    if (month.toString() === "NaN") {
      return "";
    }
    return day;
  }
  
  displayAs_HH_MM_SS_A() {
    if (this._date.getTime() == 0) {
      return "";
    }

    var dateString = this._date.toLocaleTimeString(
        'en-ca',
        {timeZoneName:'short', timeZone: this.timeZone()});
    
    return dateString;
  }
  
  displayAs_HH_MM_SS() {
    var dateString = this._date.toLocaleTimeString(
        'en-ca',
        {timeZoneName:'short', timeZone: this.timeZone()});
    dateString = dateString.split(" ")[0] + " " + dateString.split(" ")[1];
    return dateString;
  }
  
  displayAs_HH_MM_A() {
    var dateString = this._date.toLocaleTimeString(
        'en-ca',
        {timeZoneName:'short', timeZone: this.timeZone()});
    var time = dateString.split(" ")[0];
    time = time.substring(0, time.length - 3);
    var newValue = time + " " + dateString.split(" ")[1];
    return newValue;
  }
}