


export default class OptionUtils {
  
  static SortByValue = function(a,b) {
    if (a.value() > b.value()) {
      return 1;
    }
    if (a.value() < b.value()) {
      return -1;
    }
    return 0;
  }
  
  static SortByPosition = function(a,b) {
    if (a.position() > b.position()) {
      return 1;
    }
    if (a.position() < b.position()) {
      return -1;
    }
    return 0;
  }
  
  static SortByIndex = function(a,b) {
    if (a.hasIndex()) {
      if (a.index() > b.index()) {
        return 1;
      }
      if (a.index() < b.index()) {
        return -1;
      }
      return 0;
    }
    return OptionUtils.SortByPosition(a,b);
  }
}
