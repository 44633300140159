import Vue from "vue";
import Vuex from "vuex";

import auth           from "./views/auth/Store.js";
import categorys      from "./views/portal/categorys/Store.js";
import companys       from "./views/portal/companys/Store.js";
import employees      from "./views/portal/employees/Store.js";
import makers         from "./views/portal/makers/Store.js";
import schedules      from "./views/portal/schedules/Store.js";
import types          from "./views/portal/types/Store.js";
import signatures     from "./views/portal/signatures/Store.js";
import users          from "./views/portal/users/Store.js";
import vehicles       from "./views/portal/vehicles/Store.js";
import repairs        from "./views/portal/repairs/Store.js";
import repairResults  from "./views/portal/repairs/result/Store.js";
import features       from "./views/portal/features/Store.js";
import results        from "./views/portal/results/Store.js";
import questions      from "./views/portal/questions/Store.js";
import options        from "./views/portal/options/Store.js";
import inspections    from "./views/portal/inspections/Store.js";
import roles          from "./views/portal/roles/Store.js";
import register       from "./views/register/Store.js";
import signIn         from "./views/sign/in/Store.js";
import profile        from "./views/portal/profile/Store.js";
import events         from "./views/portal/events/Store.js";

import AppStatsStorage from "@/storage/AppStatsStorage.js";
import AddressStorage  from "@/storage/AddressStorage.js";
import AnswerStorage   from "@/storage/AnswerStorage.js";
import AssetStorage    from "@/storage/AssetStorage.js";
import ResultStorage   from "@/storage/ResultStorage.js";
import InvoiceStorage  from "@/storage/InvoiceStorage.js";
import CardStorage     from "@/storage/CardStorage.js";
import NoteStorage     from "@/storage/NoteStorage.js";

import InvoiceSettingsStorage from "@/storage/InvoiceSettingsStorage.js";

import TimesheetStorage from "@/storage/TimesheetStorage.js";
import TimesheetEntryStorage from "@/storage/TimesheetEntryStorage.js";

import CommentStorage from "@/domain/model/comment/CommentStorage.js";
import CheckInStorage from "@/domain/model/checkin/CheckInStorage.js";

import HomeState from "@/portals/customer/operator/views/home/HomeState.js";
import TimesheetsState from "@/portals/customer/operator/views/timesheet/list/TimesheetsState.js";
import InvoicesModel from "@/portals/customer/operator/views/invoice/list/InvoicesModel.js";

import ImageStorage from       "@/domain/model/image/ImageStorage.js";

import CheckListStorage from       "@/domain/model/checklist/CheckListStorage.js";
import ChecklistResultStorage from "@/domain/model/checklist/result/ChecklistResultStorage.js";

import CheckListItemStorage from       "@/domain/model/checklist/item/CheckListItemStorage.js";
import ChecklistItemResultStorage from "@/domain/model/checklist/item/result/ChecklistItemResultStorage.js";
import CheckListItemOptionStorage from "@/domain/model/checklist/item/option/CheckListItemOptionStorage.js";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    categorys,
    companys,
    employees,
    features,
    inspections,
    makers,
    options,
    questions,
    NoteStorage,
    
    register,
    repairs,
    repairResults,
    results,
    roles,
    schedules,
    signatures,
    signIn,
    types,
    users,
    vehicles,
    profile,
    events,

    ResultStorage,
    AddressStorage,
    AnswerStorage,
    AssetStorage,
    CommentStorage,
    InvoiceStorage,
    InvoiceSettingsStorage,
    CardStorage,
    
    AppStatsStorage,
    
    TimesheetEntryStorage,
    TimesheetStorage,
    
    CheckInStorage,
    
    HomeState,
    TimesheetsState,
    InvoicesModel,
    
    CheckListStorage,
    ChecklistResultStorage,
    CheckListItemStorage,
    CheckListItemOptionStorage,
    ChecklistItemResultStorage,
    
    ImageStorage,
  },
});
