/*import Vue from 'vue'*/
import EventUtils from '@/utils/EventUtils.js';
import MapUtils from '@/utils/MapUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';

const state = {
  list: [],
  map: {},
  active: [],
  inactive: [],
};

const getters = {
  users_store: state => state,
};

const actions = {
};

const mutations = {
  SOCKET_ONMESSAGE (state, event)  {
    if (event.status == EventUtils.STATUS.COMPLETE && event.statusCode == EventUtils.CODE.OK) {
      var inactiveList = [];
      var activeList = [];
      if (event.Payload && event.Payload.UserMap) {
        var users = event.Payload.UserMap;
        state.map = MapUtils.updateMap(users, state.map);
        state.list = Object.values(state.map);
        state.list.forEach(function(item) {
          if (item.deleted == ConstUtils.VEHICLE.VALUES.DELETED_FALSE) {
            activeList.push(item);
          } else {
            inactiveList.push(item);
          }
        });
        state.inactive = inactiveList;
        state.active = activeList;
      }
      if (event.Payload && event.Payload.additionalUserMap) {
        var additionalUserMap = event.Payload.additionalUserMap;
        state.map = MapUtils.updateMap(additionalUserMap, state.map);
        state.list = Object.values(state.map);
        state.list.forEach(function(item) {
          if (item.deleted == ConstUtils.VEHICLE.VALUES.DELETED_FALSE) {
            activeList.push(item);
          } else {
            inactiveList.push(item);
          }
        });
        state.inactive = inactiveList;
        state.active = activeList;
      }
      if (event.Payload && event.Payload.User) {
        var user = event.Payload.User;
        state.map[user[ConstUtils.FIELDS.ID]] = user;
        state.list = Object.values(state.map);
        state.list.forEach(function(item) {
          if (item.deleted == ConstUtils.VEHICLE.VALUES.DELETED_FALSE) {
            activeList.push(item);
          } else {
            inactiveList.push(item);
          }
        });
        state.inactive = inactiveList;
        state.active = activeList;
      }
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};