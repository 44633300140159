<template>
<Box>
  <Row >
    <Column :width="2" />
    <Column :width="11" class="rowEven">
      <router-link to="/portal/admin/home" class="rc-text-small rc-padding-normal rc-border-round" onclick="w3_close()" >Home</router-link>
      <router-link to="/portal/admin/stats" class="rc-text-small rc-padding-normal rc-border-round" onclick="w3_close()" >Stats</router-link>
      <router-link to="/portal/admin/companys" class="rc-text-small rc-padding-normal rc-border-round" onclick="w3_close()" >Companies</router-link>
      <router-link to="/portal/admin/employees" class="rc-text-small rc-padding-normal rc-border-round" onclick="w3_close()" >Users</router-link>
      <router-link to="/portal/admin/vehicles" class="rc-text-small rc-padding-normal rc-border-round" onclick="w3_close()" >Vehicles</router-link>
      <router-link to="/portal/admin/inspections" class="rc-text-small rc-padding-normal rc-border-round" onclick="w3_close()" >Inspections</router-link>
      <router-link to="/portal/admin/hours" class="rc-text-small rc-padding-normal rc-border-round" onclick="w3_close()" >Hours</router-link>
      <router-link to="/portal/admin/checkins" class="rc-text-small rc-padding-normal rc-border-round" onclick="w3_close()" >CheckIns</router-link>
      <router-link to="/portal/admin/invoices" class="rc-text-small rc-padding-normal rc-border-round" onclick="w3_close()" >Invoices</router-link>
      <router-link to="/portal/admin/details" class="rc-text-small rc-padding-normal rc-border-round" onclick="w3_close()" >Details</router-link>
      <router-link to="/portal/admin/new" class="rc-text-small rc-padding-normal rc-border-round" onclick="w3_close()" >New</router-link>
      <router-link to="/portal/admin/schedules" class="rc-text-small rc-padding-normal rc-border-round" onclick="w3_close()" >Schedules</router-link>
      <router-link to="/portal/admin/lists" class="rc-text-small rc-padding-normal rc-border-round" onclick="w3_close()" >Lists</router-link>
      <router-link to="/portal/admin/cards" class="rc-text-small rc-padding-normal rc-border-round" onclick="w3_close()" >Company Cards</router-link>
    </Column>
    <Column :width="2" />
  </Row>

  <div class="rc-row rc-font-medium" v-if="Strings.isOk(companyName)">
    <Column :width="2" />
    <Column :width="11" class="rc-text-centre">
      {{ companyName }}
    </Column>
    <Column :width="2" />
  </div>

  <div class="rc-row" v-if="Strings.isOk(companyName)">
    <Column :width="2" />
    <div class="rc-box-col-11">
      <router-link :to="homePage()" class="rc-text-small rc-padding-normal rc-border-round" onclick="w3_close()" >Home</router-link>
      <router-link to="/portal/operator/whatsnew" class="rc-text-small rc-padding-normal rc-border-round rc-border-round" onclick="w3_close()" >Whats New!</router-link>
      <router-link :to="compliancePage()" class="rc-text-small rc-padding-normal rc-border-round" onclick="w3_close()" >Compliance</router-link>
      <router-link :to="vehiclesPage()" class="rc-text-small rc-padding-normal rc-border-round" onclick="w3_close()" >Vehicles</router-link>
      <router-link :to="inspectionsPage()" class="rc-text-small rc-padding-normal rc-border-round" onclick="w3_close()" >Inspections</router-link>
      <router-link :to="hoursPage()" class="rc-text-small rc-padding-normal rc-border-round" onclick="w3_close()" >Hours</router-link>
      <router-link :to="checkinsPage()" class="rc-text-small rc-padding-normal rc-border-round" onclick="w3_close()" >Check-Ins</router-link>
      <router-link :to="employeesPage()" class="rc-text-small rc-padding-normal rc-border-round" onclick="w3_close()" >Users</router-link>
      <router-link :to="invoicesPage()" class="rc-text-small rc-padding-normal rc-border-round" onclick="w3_close()" >Invoices</router-link>
      <router-link :to="billingPage()" class="rc-text-small rc-padding-normal rc-border-round rc-border-round" onclick="w3_close()" >Billing</router-link>
      <router-link :to="detailsPage()" class="rc-text-small rc-padding-normal rc-border-round" onclick="w3_close()" >Company</router-link>
      <router-link :to="checklistsPage()" class="rc-text-small rc-padding-normal rc-border-round" onclick="w3_close()" >Checklists</router-link>
      <router-link :to="schedulesPage()" class="rc-text-small rc-padding-normal rc-border-round" onclick="w3_close()" >Schedules</router-link>
    </div>
    <Column :width="2" />
  </div>
</Box>
</template>

<script>
import { mapGetters } from 'vuex';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";
//import Button from "@/portals/shared/library/button/Button.vue";

export default {
  name: 'app-menu',
  components: {
    Row, Box, Column,
//    Button,
  },
  props: {
    isSmall: { type: Boolean, default: false },
  },
  data() {
    return {
      Strings: StringUtils,
    };
  },
  computed :{
    ...mapGetters([
                   'auth_user', 
                   'auth_client',
                   'auth_connected',
                   'domain',
                 ]),
    companyName: function() {
      var name = this.domain.session().companyName;
      if (!name) {
        return null;
      }
      return ContentUtils.unescape(name);
    },
  },
  methods: {
    decode: function(value) {
      return ContentUtils.unescape(value);
    },
    first: function(){
      var name = this.domain.session().user().firstDisplay();
      return name;
    },
    email: function() {
      return this.domain.session().user().emailDisplay();
    },
    goHome: function() {
      var params = {
        id: this.company().id(),
      };
      
      this.$router.push({
        name: ConstUtils.ROUTES.STATS.ADMIN.HOME,
        params: params,
      });
    },
    company: function() {
      return this.domain.session().company();
    },
    companyId: function() {
      return this.company().id().replace("#","%23");
    },
    homePage: function() {
      return "/portal/admin/companys/" + this.companyId() + "/home"
    },
    compliancePage: function() {
      return "/portal/admin/companys/" + this.companyId() + "/compliance";
    },
    vehiclesPage: function() {
      return  "/portal/admin/companys/" + this.companyId() + "/vehicles";
    },
    inspectionsPage: function() {
      return  "/portal/admin/companys/" + this.companyId() + "/inspections";
    },
    hoursPage: function() {
      return  "/portal/admin/companys/" + this.companyId() + "/hours";
    },
    checkinsPage: function() {
      return  "/portal/admin/companys/" + this.companyId() + "/checkins";
    },
    employeesPage: function() {
      return  "/portal/admin/companys/" + this.companyId() + "/employees";
    },
    invoicesPage: function() {
      return  "/portal/admin/companys/" + this.companyId() + "/invoices";
    },
    billingPage: function() {
      return  "/portal/admin/companys/" + this.companyId() + "/billing";
    },
    detailsPage: function() {
      return  "/portal/admin/companys/" + this.companyId() + "/details";
    },
    schedulesPage: function() {
      return  "/portal/admin/companys/" + this.companyId() + "/schedules";
    },
    checklistsPage: function() {
      return  "/portal/admin/companys/" + this.companyId() + "/checklists";
    },
  },
}
</script>