
import RcDomainObject from "@/domain/common/RcDomainObject";

export default class Category extends RcDomainObject {
  
  static MODEL_NAME = 'VehicleCategory';
  
  static FIELDS = {
    NAME : 'name',
  }
  
  static VALUES = {
    TRAILER: 'trailer',
    TRUCK: 'pickup',
    BUS: 'bus',
  }
  
  constructor(domain, data) {
    super(domain, data, Category.MODEL_NAME);
  }

  fields() {
    return Category.FIELDS;
  }
  
  find() {
    return this.domain.categorys().findById(this.id());
  }
  
  name() {
    return this.getString(Category.FIELDS.NAME);
  }
  
  isTrailer() {
    return this.name().toLowerCase() === Category.VALUES.TRAILER || 
        this.id() === "#23:5"; 
  }
  
  isBus() {
    var id = this.id();
    var name = this.name().toLowerCase();
    return name === Category.VALUES.BUS || 
        id === "#23:6" ||
        id === "#23:15";
  }
  
  isTruck() {
    return !this.isTrailer() && !this.isBus();
  }
  
  isVehicle() {
    return this.isTruck() || this.isBus();
  }
  
  copy() {
    var data = this._copy();
    return new Category(this.domain, data);
  }
}