/*import Vue from 'vue'*/
import EventUtils from '@/utils/EventUtils.js';
import MapUtils from '@/utils/MapUtils.js';
import ConstUtils from '@/utils/ConstUtils.js'
import RcResponseEvent from '@/domain/model/events/RcResponseEvent.js';

import CheckListMap from '@/domain/model/checklist/CheckListMap.js';
import CheckList from '@/domain/model/checklist/CheckList.js';

const state = {
  list: [],
  map: {},
};

const getters = {
    CheckListStorage: state => state,
};

const actions = {
};

const mutations = {
  SOCKET_ONMESSAGE (state, event)  {
    if (event.status == EventUtils.STATUS.COMPLETE && event.statusCode == EventUtils.CODE.OK) {
      var payload = event[RcResponseEvent.Fields.PAYLOAD];
      if (payload) {
        var map = payload[CheckListMap.MODEL_NAME];
        if (map) {
          state.map = MapUtils.updateMap(map, state.map);
        }
        var item = payload[CheckList.MODEL_NAME];
        if (item) {
          state.map[item[ConstUtils.FIELDS.ID]] = item;
        }
        state.list = Object.values(state.map);
      }
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};