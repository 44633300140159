
import StringUtils from '@/utils/StringUtils.js';
import RcDomainObject from "../../../../common/RcDomainObject";
import Company from "../../../company/Company.js";

export default class CheckListItemOption extends RcDomainObject {
  
  static MODEL_NAME = 'CheckListItemOption';
  
  static Fields = {
      Index: "index",
      Name: "name",
      Company: "Company",
      Status: "status",
      Category: "category",
  }
  
  static Values = {
    Category: {
      Good: "none",
      Minor: "minor",
      Major: "major",
    }
  }
  
  constructor(domain, data) {
    super(domain, data, CheckListItemOption.MODEL_NAME);
  }

  fields() {
    return CheckListItemOption.Fields;
  }
  
  find() {
    var result = this.domain.checklists().items().options().findById(this.id());
    if (result.id() !== this.id()) {
      result = this;
    }
    
    return result;
  }

  index() {
    return this.getInt(CheckListItemOption.Fields.Index);
  }
  
  withIndex(index) {
    this.put(CheckListItemOption.Fields.Index, index);
    return this;
  }
  
  name() {
    return this.getString(CheckListItemOption.Fields.Name);
  }
  
  withName(name) {
    this.put(CheckListItemOption.Fields.Name, name);
    return this;
  }
  
  company() {
    var data = this.get(CheckListItemOption.Fields.Company);
    var company = new Company(this.domain, data);
    return company;
  }
  
  withCompany(company) {
    this.put(CheckListItemOption.Fields.Company, company);
    return this;
  }
  
  withCategoryGood() {
    return this.withCategory(CheckListItemOption.Values.Category.Good)
  }

  withCategoryMinor() {
    return this.withCategory(CheckListItemOption.Values.Category.Minor)
  }

  withCategoryMajor() {
    return this.withCategory(CheckListItemOption.Values.Category.Major)
  }

  withCategory(category) {
    this.put(CheckListItemOption.Fields.Category, category);
    return this;
  }
  
  category() {
    var category = this.getString(CheckListItemOption.Fields.Category);
    if (StringUtils.isEmpty(category)) {
      category = CheckListItemOption.Values.Category.Good;
    }
    return category;
  }
  
  copy() {
    var data = this._copy();
    return new CheckListItemOption(this.domain, data);
  }
}